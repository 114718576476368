export const GET_CHART_TERITORY = 'features/dashboard/GET_CHART_TERITORY';
export const GET_CHART_TERITORY_SUCCESS = 'features/dashboard/GET_CHART_TERITORY_SUCCESS';
export const GET_CHART_TERITORY_ERROR = 'features/dashboard/GET_CHART_TERITORY_ERROR';

export const GET_TERITORY_BY_JOB = 'features/dashboard/GET_TERITORY_BY_JOB';
export const GET_TERITORY_BY_JOB_SUCCESS = 'features/dashboard/GET_TERITORY_BY_JOB_SUCCESS';
export const GET_TERITORY_BY_JOB_ERROR = 'features/dashboard/GET_TERITORY_BY_JOB_ERROR';


export const GET_CHART_STORE = 'features/dashboard/GET_CHART_STORE';
export const GET_CHART_STORE_SUCCESS = 'features/dashboard/GET_CHART_STORE_SUCCESS';
export const GET_CHART_STORE_ERROR = 'features/dashboard/GET_CHART_STORE_ERROR';

export const SET_PARAMS = 'features/dashboard/SET_PARAMS';
