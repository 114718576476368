import { all } from 'redux-saga/effects';

import authSagas from '../features/login/sagas';
import dashboardSagas from '../features/dashboard/sagas';
import companySagas from '../features/company/sagas';
import storeSagas from '../features/store/sagas';
import employeeSagas from '../features/employee/sagas';
import projectSagas from '../features/project/sagas';
import importSagas from '../features/import/sagas';
import skuInProjectSagas from '../features/project/pages/sku/sagas';
import regionInProjectSagas from '../features/project/pages/region/sagas';
import storeTypeInProjectSagas from '../features/project/pages/storeType/sagas';
import storeInProject from '../features/project/pages/store/sagas';
import planogramInProject from '../features/project/pages/planogram/sagas';
import storeTypePlanogramInProject from '../features/project/pages/storeTypePlanogram/sagas';
import regionPlanogramInProject from '../features/project/pages/regionPlanogram/sagas';
import skuPlanogramInProject from '../features/project/pages/skuPlanogram/sagas';
import imageCategorySagas from '../features/imageCategory/sagas';
import employeeInProject from '../features/project/pages/employee/sagas';
import imageTypeInProjectSagas from '../features/project/pages/imageType/sagas';
import teritoryInProjectSagas from '../features/territory/sagas';
import myProfileInProjectSagas from '../features/myProfile/sagas';
import jobInProjectSagas from '../features/job/sagas';
import publicsagas from '../app/sagas';
import photoSagas from '../features/photo/sagas';
import settingSagas from '../features/setting/saga';

export default function* rootSaga() {
  yield all([
    ...authSagas,
    ...dashboardSagas,
    ...companySagas,
    ...storeSagas,
    ...employeeSagas,
    ...projectSagas,
    ...importSagas,
    ...skuInProjectSagas,
    ...regionInProjectSagas,
    ...storeTypeInProjectSagas,
    ...storeInProject,
    ...planogramInProject,
    ...storeTypePlanogramInProject,
    ...regionPlanogramInProject,
    ...skuPlanogramInProject,
    ...imageCategorySagas,
    ...employeeInProject,
    ...imageTypeInProjectSagas,
    ...teritoryInProjectSagas,
    ...myProfileInProjectSagas,
    ...jobInProjectSagas,
    ...publicsagas,
    ...photoSagas,
    ...settingSagas,
  ]);
}
