import { takeEvery, call } from 'redux-saga/effects';
import * as actionTypes from './actionTypes';
import { projectApi } from '../../api/projectApi';

function* handleGetList({ payload }) {
  const { params, callback } = payload;

  try {
    const result = yield call(projectApi.getList, params);

    callback({ error: false, data: result });
  } catch (error) {
    callback({ error: true, errors: error.errors });
  }
}

function* handleCreate({ payload }) {
  const { data, callback } = payload;

  try {
    const result = yield call(projectApi.create, data);

    callback({ error: false, data: result });
  } catch (error) {
    callback({ error: true, errors: error.errors });
  }
}

function* handleGetDetail({ payload }) {
  const { sid, callback } = payload;

  try {
    const result = yield call(projectApi.getDetail, sid);

    callback({ error: false, data: result });
  } catch (error) {
    callback({ error: true, errors: error.errors });
  }
}

function* handleUpdate({ payload }) {
  const { data, callback } = payload;

  try {
    const result = yield call(projectApi.update, data);

    callback({ error: false, data: result });
  } catch (error) {
    callback({ error: true, errors: error.errors });
  }
}

export default [
  takeEvery(actionTypes.GET_LIST, handleGetList),
  takeEvery(actionTypes.CREATE, handleCreate),
  takeEvery(actionTypes.GET_DETAIL, handleGetDetail),
  takeEvery(actionTypes.UPDATE, handleUpdate),
];
