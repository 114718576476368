export const GET_LIST = 'features/myProfile/GET_LIST';
export const GET_LIST_SUCCESS = 'features/myProfile/GET_LIST_SUCCESS';
export const GET_LIST_ERROR = 'features/myProfile/GET_LIST_ERROR';

export const CREATE = 'features/myProfile/CREATE';
export const CREATE_SUCCESS = 'features/myProfile/CREATE_SUCCESS';
export const CREATE_ERROR = 'features/myProfile/CREATE_ERROR';

export const CREATE_AVATAR = 'features/myProfile/CREATE_AVATAR';
export const CREATE_AVATAR_SUCCESS = 'features/myProfile/CREATE_AVATAR_SUCCESS';
export const CREATE_AVATAR_ERROR = 'features/myProfile/CREATE_AVATAR_ERROR';

export const GET_DETAIL = 'features/myProfile/GET_DETAIL';
export const GET_DETAIL_SUCCESS = 'features/myProfile/GET_DETAIL_SUCCESS';
export const GET_DETAIL_ERROR = 'features/myProfile/GET_DETAIL_ERROR';

export const UPDATE = 'features/myProfile/UPDATE';
export const UPDATE_SUCCESS = 'features/myProfile/UPDATE_SUCCESS';
export const UPDATE_ERROR = 'features/myProfile/UPDATE_ERROR';

export const CHANGEPASSWORD = 'features/myProfile/CHANGEPASSWORD';


export const SET_PARAMS = 'features/myProfile/SET_PARAMS';
