import { takeEvery, call } from 'redux-saga/effects';
import * as actionTypes from './actionTypes';
import { skuApi } from '../../../../api/projectApi';

function* handleGetSkuList({ payload }) {
  const { projectSid, params, callback } = payload;

  try {
    const result = yield call(skuApi.getList, projectSid, params);

    callback({ error: false, data: result });
  } catch (error) {
    callback({ error: true, errors: error.errors });
  }
}

function* handleCreateSku({ payload }) {
  const { data, callback } = payload;

  try {
    const result = yield call(skuApi.create, data);

    callback({ error: false, data: result });
  } catch (error) {
    callback({ error: true, errors: error.errors });
  }
}

function* handleGetSkuDetail({ payload }) {
  const { data, callback } = payload;

  try {
    const result = yield call(skuApi.getDetail, data);

    callback({ error: false, data: result });
  } catch (error) {
    callback({ error: true, errors: error.errors });
  }
}

function* handleUpdateSku({ payload }) {
  const { data, callback } = payload;

  try {
    const result = yield call(skuApi.update, data);

    callback({ error: false, data: result });
  } catch (error) {
    callback({ error: true, errors: error.errors });
  }
}

export default [
  takeEvery(actionTypes.GET_SKU_LIST, handleGetSkuList),
  takeEvery(actionTypes.CREATE_SKU, handleCreateSku),
  takeEvery(actionTypes.GET_SKU_DETAIL, handleGetSkuDetail),
  takeEvery(actionTypes.UPDATE_SKU, handleUpdateSku),
];
