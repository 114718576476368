import { takeEvery, call } from 'redux-saga/effects';
import * as actionTypes from './actionTypes';
import { storeApi } from '../../../../api/projectApi';

function* handleGetStoreList({ payload }) {
  const { projectSid, params, callback } = payload;

  try {
    const result = yield call(storeApi.getList, projectSid, params);

    callback({ error: false, data: result });
  } catch (error) {
    callback({ error: true, errors: error.errors });
  }
}

function* handleCreateStore({ payload }) {
  const { data, callback } = payload;

  try {
    const result = yield call(storeApi.create, data);

    callback({ error: false, data: result });
  } catch (error) {
    callback({ error: true, errors: error.errors });
  }
}

export default [
  takeEvery(actionTypes.GET_STORE_LIST, handleGetStoreList),
  takeEvery(actionTypes.CREATE_STORE, handleCreateStore),
];
