import { takeEvery, call } from 'redux-saga/effects';
import * as actionTypes from './actionsTypes';
import settingApi from '../../api/setting';

function* handleSettingImportStore({ payload }) {
  const { params, callback } = payload;

  try {
    const result = yield call(settingApi.getSettingImportStore, params);
    callback({ error: false, data: result });
  } catch (error) {
    callback({ error: true, errors: error.errors });
  }
}
export default [
  takeEvery(actionTypes.SETTING_IMPORT_STORE, handleSettingImportStore),
];
