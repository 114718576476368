import { takeEvery, call } from 'redux-saga/effects';
import * as actionTypes from './actionTypes';
import dashboardApi from '../../api/dashboardApi';

function* handleGetChartTerritory({ payload }) {
  const { callback, job_sid, territory_sid } = payload;

  try {
    const result = yield call(dashboardApi.getChartList, job_sid, territory_sid);

    callback({ error: false, data: result });
  } catch (error) {
    callback({ error: true, errors: error.errors });
  }
}

function* handleGetTerritoryByJob({ payload }) {
  const { callback, job_sid } = payload;

  try {
    const result = yield call(dashboardApi.getTerritoryByJob, job_sid);

    callback({ error: false, data: result });
  } catch (error) {
    callback({ error: true, errors: error.errors });
  }
}

function* handleGetChartStore({ payload }) {
  const { callback, job_sid, territory_sid } = payload;
  // console.log('handleGetChartStore', payload)
  try {
    const result = yield call(dashboardApi.getStoreChat, job_sid, territory_sid);

    callback({ error: false, data: result });
  } catch (error) {
    callback({ error: true, errors: error.errors });
  }
}

export default [
  takeEvery(actionTypes.GET_CHART_TERITORY, handleGetChartTerritory),
  takeEvery(actionTypes.GET_TERITORY_BY_JOB, handleGetTerritoryByJob),
  takeEvery(actionTypes.GET_CHART_STORE, handleGetChartStore),
];
