export const GET_PLANOGRAM_LIST = 'features/project/GET_PLANOGRAM_LIST';
export const GET_PLANOGRAM_LIST_SUCCESS =
  'features/project/GET_PLANOGRAM_LIST_SUCCESS';
export const GET_PLANOGRAM_LIST_ERROR =
  'features/project/GET_PLANOGRAM_LIST_ERROR';

export const CREATE_PLANOGRAM = 'features/project/CREATE_PLANOGRAM';
export const CREATE_PLANOGRAM_SUCCESS =
  'features/project/CREATE_PLANOGRAM_SUCCESS';
export const CREATE_PLANOGRAM_ERROR = 'features/project/CREATE_PLANOGRAM_ERROR';

export const GET_PLANOGRAM_DETAIL = 'features/project/GET_PLANOGRAM_DETAIL';
export const GET_PLANOGRAM_DETAIL_SUCCESS =
  'features/project/GET_PLANOGRAM_DETAIL_SUCCESS';
export const GET_PLANOGRAM_DETAIL_ERROR =
  'features/project/GET_PLANOGRAM_DETAIL_ERROR';

export const UPDATE_PLANOGRAM = 'features/project/UPDATE_PLANOGRAM';
export const UPDATE_PLANOGRAM_SUCCESS =
  'features/project/UPDATE_PLANOGRAM_SUCCESS';
export const UPDATE_PLANOGRAM_ERROR = 'features/project/UPDATE_PLANOGRAM_ERROR';
