import axiosClient from './axiosClient';

const companyApi = {
  getList: (params) => {
    return axiosClient.get('/companies', { params });
  },
  getTerritoryCompany: (sid, params) => {
    return axiosClient.get(`/companies/${sid}/territories`, { params });
  },
  create: (data) => {
    return axiosClient.post('/companies', data);
  },
  getDetail: (sid) => {
    return axiosClient.get(`/companies/${sid}`);
  },
  update: ({ sid, ...data }) => {
    return axiosClient.put(`/companies/${sid}`, data);
  },
  partialUpdate: ({ sid, ...data }) => {
    return axiosClient.patch(`/companies/${sid}`, data);
  },
  getListCompanyFull: (params) => {
    return axiosClient.get('/list_companies', { params });
  },
};

export default companyApi;
