import axiosClient from './axiosClient';

const photoApi = {
  getList: (params) => {
    return axiosClient.get('/photos', { params });
  },
  getListJobOfCompany: (territory_sid, company_sid, params) => {
    return axiosClient.get(
      `/territory/jobs?territory_sid=${territory_sid}&company_sid=${company_sid}`,
      { params },
    );
  },
  getListStoreOfCompany: (territory_sid, company_sid, params) => {
    return axiosClient.get(
      `/territory/stores?territory_sid=${territory_sid}&company_sid=${company_sid}`,
      { params },
    );
  },
  getListPhotoElevation: (params) => {
    return axiosClient.get('/photo_elevations', { params });
  },
  getListPhotoTag: (params) => {
    return axiosClient.get('/photo_tag', { params });
  },
  createPhoto: (data) => {
    return axiosClient.post('/photos', data);
  },
  postPhoto: ({ sid, data }) => {
    return axiosClient.post(`/photos/${sid}/upload-photo`, data);
  },
  update: ({ sid, ...data }) => {
    return axiosClient.put(`/photos/${sid}`, data);
  },
  deletePhoto: (data) => {
    return axiosClient.delete(`/photos`, { data });
  },
  downloadPhoto: (data) => {
    return axiosClient.post(`/photos/download`, { data });
  },
  getListPhotoInteriorExterior: (params) => {
    return axiosClient.get('/photos/interior_exterior', { params });
  },
};

export default photoApi;
