import { takeEvery, put, call } from 'redux-saga/effects';
import * as actionTypes from './actionTypes';
import * as actions from './actions';

// import Notification from '../../components/Notification';
import authApi from '../../api/authApi';
import { parseJwt } from '../../utils/helpers';

function* handleLogin({ payload }) {
  const { data, callback } = payload;
  try {
    const result = yield call(authApi.login, data);
    const { token, role } = result;

    if (['EMPLOYEE'].includes(role)) {
      callback({ error: true, isNotification: true });
    } else {
      yield localStorage.setItem('token', token);
      yield localStorage.setItem('role', role);
      callback({ error: false });
      yield put(actions.handleLoginSuccess(result));
    }
  } catch (error) {
    callback({ error: true });
    yield put(actions.handleLoginError());
  }
}

function* handleResetPassword({ payload }) {
  const { data, callback } = payload;
  // console.log('data :', data);
  try {
    const result = yield call(authApi.resetPassword, data);

    callback({ error: false, data: result });
  } catch (error) {
    callback({ error: true, errors: error.errors });
  }
}

function* handleLogout() {
  yield localStorage.removeItem('token');
  yield localStorage.removeItem('role');
}

function* handleCheckAuth() {
  const token = localStorage.getItem('token');
  const role = localStorage.getItem('role');
  // Convert JWT to Object
  const parseResult = parseJwt(token);

  if (parseResult) {
    if (
      parseResult.exp < new Date().getTime() / 1000 ||
      parseResult.user?.role !== role
    ) {
      yield put(actions.handleLogout());
    } else {
      yield put(actions.handleLoginSuccess(parseResult.user));
    }
  } else {
    yield put(actions.handleLogout());
  }
}

export default [
  takeEvery(actionTypes.LOGIN, handleLogin),
  takeEvery(actionTypes.RESET_PASSWORD, handleResetPassword),
  takeEvery(actionTypes.LOGOUT, handleLogout),
  takeEvery(actionTypes.CHECK_AUTH, handleCheckAuth),
];
