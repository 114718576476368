export const GET_LIST = 'features/store/GET_LIST';
export const GET_LIST_SUCCESS = 'features/store/GET_LIST_SUCCESS';
export const GET_LIST_ERROR = 'features/store/GET_LIST_ERROR';

export const GET_LIST_PHOTO_FILTER_STORE =
  'features/store/GET_LIST_PHOTO_FILTER_STORE';
export const GET_LIST_PHOTO_FILTER_STORE_SUCCESS =
  'features/store/GET_LIST_PHOTO_FILTER_STORE_SUCCESS';
export const GET_LIST_PHOTO_FILTER_STORE_ERROR =
  'features/store/GET_LIST_PHOTO_FILTER_STORE_ERROR';

export const CREATE = 'features/store/CREATE';
export const CREATE_SUCCESS = 'features/store/CREATE_SUCCESS';
export const CREATE_ERROR = 'feature/store/CREATE_ERROR';

export const GET_DETAIL = 'feature/store/GET_DETAIL';
export const GET_DETAIL_SUCCESS = 'feature/store/GET_DETAIL_SUCCESS';
export const GET_DETAIL_ERROR = 'feature/store/GET_DETAIL_ERROR';

export const UPDATE = 'feature/store/UPDATE';
export const UPDATE_SUCCESS = 'feature/store/UPDATE_SUCCESS';
export const UPDATE_ERROR = 'feature/store/UPDATE_ERROR';

export const UPLOAD_FILE_DATA = 'feature/store/UPLOAD_FILE_DATA';
export const UPLOAD_FILE_DATA_SUCCESS =
  'feature/store/UPLOAD_FILE_DATA_SUCCESS';
export const UPLOAD_FILE_DATA_ERROR = 'feature/store/UPLOAD_FILE_DATA_ERROR';

export const UPDATE_IMG = 'feature/store/UPDATE_IMG';
export const UPDATE_IMG_SUCCESS = 'feature/store/UPDATE_IMG_SUCCESS';
export const UPDATE_IMG_ERROR = 'feature/store/UPDATE_IMG_ERROR';

export const UPDATE_FILE = 'feature/store/UPDATE_FILE';
export const UPDATE_FILE_SUCCESS = 'feature/store/UPDATE_FILE_SUCCESS';
export const UPDATE_FILE_ERROR = 'feature/store/UPDATE_FILE_ERROR';

export const SET_PARAMS = 'feature/store/SETPARAMS';

export const GET_STORE_TYPE = 'features/store/GET_STORE_TYPE';
export const GET_STORE_TYPE_SUCCESS = 'features/store/GET_STORE_TYPE_SUCCESS';
export const GET_STORE_TYPE_ERROR = 'features/store/GET_STORE_TYPE_ERORR';

export const GET_CLIMATE_REGION = 'feature/store/GET_CLIMATE_REGION';
export const GET_CLIMATE_REGION_SUCCESS = 'feature/store/GET_CLIMATE_REGION_SUCCESS';
export const GET_CLIMATE_REGION_ERROR = 'feature/store/GET_CLIMATE_REGION_ERROR';

export const EXPORT = 'features/jobs/EXPORT';
export const EXPORT_SUCCESS = 'features/jobs/EXPORT_SUCCESS';
export const EXPORT_ERROR = 'features/jobs/EXPORT_ERROR';

export const DOWNLOAD = 'features/jobs/DOWNLOAD';
export const DOWNLOAD_SUCCESS = 'features/jobs/DOWNLOAD_SUCCESS';
export const DOWNLOAD_ERROR = 'features/jobs/DOWNLOAD_ERROR';


export const GET_LIST_PHOTO_JOB_STORE_FILTER =
  'features/jobs/GET_LIST_PHOTO_JOB_STORE_FILTER';
export const GET_LIST_PHOTO_JOB_STORE_FILTER_SUCCESS =
  'features/jobs/GET_LIST_PHOTO_JOB_STORE_FILTER_SUCCESS';
export const GET_LIST_PHOTO_JOB_STORE_FILTER_ERROR =
  'features/jobs/GET_LIST_PHOTO_JOB_STORE_FILTER_ERROR';