export const GET_STORE_TYPE_LIST = 'features/project/GET_STORE_TYPE_LIST';
export const GET_STORE_TYPE_LIST_SUCCESS =
  'features/project/GET_STORE_TYPE_LIST_SUCCESS';
export const GET_STORE_TYPE_LIST_ERROR =
  'features/project/GET_STORE_TYPE_LIST_ERROR';

export const CREATE_STORE_TYPE = 'features/project/CREATE_STORE_TYPE';
export const CREATE_STORE_TYPE_SUCCESS =
  'features/project/CREATE_STORE_TYPE_SUCCESS';
export const CREATE_STORE_TYPE_ERROR =
  'features/project/CREATE_STORE_TYPE_ERROR';

export const GET_STORE_TYPE_DETAIL = 'features/project/GET_STORE_TYPE_DETAIL';
export const GET_STORE_TYPE_DETAIL_SUCCESS =
  'features/project/GET_STORE_TYPE_DETAIL_SUCCESS';
export const GET_STORE_TYPE_DETAIL_ERROR =
  'features/project/GET_STORE_TYPE_DETAIL_ERROR';

export const UPDATE_STORE_TYPE = 'features/project/UPDATE_STORE_TYPE';
export const UPDATE_STORE_TYPE_SUCCESS =
  'features/project/UPDATE_STORE_TYPE_SUCCESS';
export const UPDATE_STORE_TYPE_ERROR =
  'features/project/UPDATE_STORE_TYPE_ERROR';
