import { takeEvery, call, all } from 'redux-saga/effects';

import * as actionType from './actionsTypes';
import storeApi from '../../api/storeApi';

function* handleGetList({ payload }) {
  const { params, callback } = payload;
  try {
    const result = yield call(storeApi.getList, params);
    callback({ error: false, data: result });
  } catch (error) {
    callback({ error: true, errors: error.error });
  }
}

function* handleGetListPhotoFilter({ payload }) {
  const { params, callback } = payload;

  try {
    const result = yield call(storeApi.getFilterListPhotoStore, params);
    callback({ error: false, data: result });
  } catch (error) {
    callback({ error: true, errors: error.errors });
  }
}
function* handleCreate({ payload }) {
  const { data, callback } = payload;
  try {
    const result = yield call(storeApi.create, data);
    callback({ error: false, data: result });
  } catch (error) {
    callback({ error: true, errors: error.error });
  }
}

function* uploadFileData({ payload }) {
  const { data, callback } = payload;
  try {
    const result = yield call(storeApi.create, data);
    callback({ error: false, data: result });
  } catch (error) {
    callback({ error: true, errors: error.errors });
  }
}

function* handleUpdateImg({ payload }) {
  const { data, callback } = payload;
  try {
    const result = yield call(storeApi.postPhoto, data);
    callback({ error: false, data: result });
  } catch (error) {
    callback({ error: true, errors: error.error });
  }
}

function* handleUpdateFile({ payload }) {
  const { data, callback } = payload;
  try {
    const result = yield call(storeApi.postFile, data);
    callback({ error: false, data: result });
  } catch (error) {
    callback({ error: true, errors: error.error });
  }
}

function* handleGetDetail({ payload }) {
  const { sid, callback } = payload;

  try {
    const result = yield call(storeApi.getDetail, sid);

    callback({ error: false, data: result });
  } catch (error) {
    callback({ error: true, errors: error.errors });
  }
}
function* handleGetStoreType({ payload }) {
  const { data, callback } = payload;
  try {
    const result = yield call(storeApi.getStoreType, data);
    callback({ error: false, data: result });
  } catch (error) {
    callback({ error: true, errors: error.error });
  }
}
function* handleGetClimateRegion({ payload }) {
  const { data, callback } = payload;
  try {
    const result = yield call(storeApi.getClimateRegion, data);
    callback({ error: false, data: result });
  } catch (error) {
    callback({ error: true, errors: error.error });
  }
}

function* handleUpdate({ payload }) {
  const { data, callback } = payload;
  try {
    const { sid, img_store, file_store, ...rest } = data;
    if (img_store && file_store) {
      const formDataImg = new FormData();
      formDataImg.append('img_store', img_store);
      const formDataFile = new FormData();
      formDataFile.append('file_store', file_store);
      const result = yield all([
        call(storeApi.postPhoto, sid, formDataImg),
        call(storeApi.postFile, sid, formDataFile),
        call(storeApi.update, { sid, ...rest }),
      ]);
    } else if (img_store) {
      const formDataImg = new FormData();
      formDataImg.append('img_store', img_store);
      const result = yield all([
        call(storeApi.postPhoto, sid, formDataImg),
        call(storeApi.update, { sid, ...rest }),
      ]);
    } else if (file_store) {
      const formDataFile = new FormData();
      formDataFile.append('file_store', file_store);
      const result = yield all([
        call(storeApi.postFile, sid, formDataFile),
        call(storeApi.update, { sid, ...rest }),
      ]);
    } else {
      const result = yield all([call(storeApi.update, { sid, ...rest })]);
    }
    callback({ error: false });
  } catch (error) {
    callback({ error: true, errors: error.errors });
  }
}

export default [
  takeEvery(actionType.GET_LIST, handleGetList),
  takeEvery(actionType.GET_LIST_PHOTO_FILTER_STORE, handleGetListPhotoFilter),
  takeEvery(actionType.UPDATE, handleUpdate),
  takeEvery(actionType.UPDATE_IMG, handleUpdateImg),
  takeEvery(actionType.UPDATE_FILE, handleUpdateFile),
  takeEvery(actionType.GET_DETAIL, handleGetDetail),
  takeEvery(actionType.CREATE, handleCreate),
  takeEvery(actionType.GET_STORE_TYPE, handleGetStoreType),
  takeEvery(actionType.GET_CLIMATE_REGION, handleGetClimateRegion),
  takeEvery(actionType.UPLOAD_FILE_DATA, uploadFileData),
  
];
