import React from 'react';

/**
 * Lưu ý:
 * - Khi sử dụng icon trong ionicon-v2 (leftIcon: 'ion-person-stalker')
 * - Khi sử dụng icon trong Antd (leftIcon: <AppstoreOutlined />)
 * - Khi sử dụng icon trong react/icons (leftIcon: <FaStore classNameName="anticon" />)
 */

const options = [
  {
    key: 'dashboard',
    label: 'sidebar.dashboard',
    leftIcon: <i className="far fa-layer-group icon-padding" />,
  },
  {
    key: 'companies',
    label: 'sidebar.company',
    leftIcon: <i className="far fa-border-all icon-padding" />,
  },
  {
    key: 'stores',
    label: 'sidebar.store',
    leftIcon: <i className="far fa-store icon-padding" />,
    children: [
      {
        key: 'stores',
        label: 'sidebar.store',
      },
      {
        key: 'territory',
        label: 'sidebar.territory',
      },
    ],
  },
  {
    key: 'jobs',
    label: 'sidebar.job',
    leftIcon: <i className="fal fa-list-alt icon-padding" />,
  },
  {
    key: 'photos',
    label: 'sidebar.photo',
    leftIcon: <i className="fal fa-file-image icon-padding" />,
  },

  {
    key: 'employees',
    label: 'sidebar.employee',
    leftIcon: <i className="far fa-user-plus icon-padding" />,
  },
  {
    key: 'activities',
    label: 'sidebar.activities',
    leftIcon: <i className="far fa-history icon-padding" />,
  },
  // {
  //   key: 'setting',
  //   label: 'sidebar.setting',
  //   leftIcon: <i className="far fa-cogs icon-padding" />,
  // },
];

export default options;
