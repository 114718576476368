import { takeEvery, call } from 'redux-saga/effects';
import * as actionTypes from './actionTypes';
import { imageTypeApi } from '../../../../api/projectApi';

function* handleGetImageTypeList({ payload }) {
  const { projectSid, params, callback } = payload;

  try {
    const result = yield call(imageTypeApi.getList, projectSid, params);

    callback({ error: false, data: result });
  } catch (error) {
    callback({ error: true, errors: error.errors });
  }
}

function* handleCreateImageType({ payload }) {
  const { data, callback } = payload;

  try {
    const result = yield call(imageTypeApi.create, data);

    callback({ error: false, data: result });
  } catch (error) {
    callback({ error: true, errors: error.errors });
  }
}

function* handleGetImageTypeDetail({ payload }) {
  const { data, callback } = payload;

  try {
    const result = yield call(imageTypeApi.getDetail, data);

    callback({ error: false, data: result });
  } catch (error) {
    callback({ error: true, errors: error.errors });
  }
}

function* handleUpdateImageType({ payload }) {
  const { data, callback } = payload;

  try {
    const result = yield call(imageTypeApi.update, data);

    callback({ error: false, data: result });
  } catch (error) {
    callback({ error: true, errors: error.errors });
  }
}

export default [
  takeEvery(actionTypes.GET_IMAGE_TYPE_LIST, handleGetImageTypeList),
  takeEvery(actionTypes.CREATE_IMAGE_TYPE, handleCreateImageType),
  takeEvery(actionTypes.GET_IMAGE_TYPE_DETAIL, handleGetImageTypeDetail),
  takeEvery(actionTypes.UPDATE_IMAGE_TYPE, handleUpdateImageType),
];
