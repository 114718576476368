import axiosClient from './axiosClient';

const jobsApi = {
  getList: (params) => {
    return axiosClient.get('/jobs', { params });
  },
  getListJobStore: (sid, params) => {
    return axiosClient.get(`/job/${sid}/store`, { params });
  },
  getListJobPlan: (plan_sid, params) => {
    return axiosClient.get(`/job/plan/${plan_sid}`, { params });
  },
  getFilterListPhoto: (params) => {
    return axiosClient.get(`/photos`, { params });
  },
  getFilterListPhotoJobStore: (params) => {
    return axiosClient.get(`/photos`, { params });
  },
  create: (data) => {
    return axiosClient.post('/jobs', data);
  },
  export: (job_sid, plan_sid, data) => {
    return axiosClient.post(`/export_data`, data);
  },
  download: (job_sid, plan_sid, data) => {
    return axiosClient.post(`/photos/download`, data);
  },
  setStatusInactive: (sid) => {
    return axiosClient.post(`job/${sid}/status`);
  },
  setOldStatus: (sid) => {
    return axiosClient.post(`job/${sid}/status_restar`);
  },

  getDetail: (sid) => {
    return axiosClient.get(`/jobs/${sid}`);
  },
  update: ({ sid, ...data }) => {
    return axiosClient.put(`/jobs/${sid}`, data);
  },
  partialUpdate: ({ sid, ...data }) => {
    return axiosClient.patch(`/jobs/${sid}`, data);
  },
  plan: ({ sid, ...data }) => {
    return axiosClient.put(`/plans/${sid}`, data);
  },
  storeBojUploadFile: (data) => {
    return axiosClient.post(`/store_job/store`, data);
  },
  getUploadJobStoreEdit: ({ plan_sid }) => {
    return axiosClient.get(`/store_job/${plan_sid}`);
  },
  getListFields: (params) => {
    return axiosClient.get('/fields', { params });
  },
  getDataMaterials: (params) => {
    return axiosClient.get('/materials', { params });
  },
  updateEditJobStore: ({ data, plan_sid, data_diff }) => {
    var bodyFormData = new FormData();
    bodyFormData.append('data', data);
    bodyFormData.append('data_diff', data_diff);
    return axiosClient.post(`/job/plan/${plan_sid}/upload`, bodyFormData);
  },
};

export default jobsApi;
