import axiosClient from './axiosClient';

const employeeApi = {
  getListType: (params) => {
    return axiosClient.get('/activity_type', { params });
  },
  getListStore: (params) => {
    return axiosClient.get('/stores', { params });
  },
  getListJob: (params) => {
    return axiosClient.get('/jobs', { params });
  },
  getList: (params) => {
    return axiosClient.get('/employees', { params });
  },
  getListCompanyInEmployee: (sid, params) => {
    return axiosClient.get(`/employees/${sid}/companies`, { params });
  },
  getListCustomerToCompany: (params) => {
    return axiosClient.get('/customer/company/photos', { params });
  },
  create: (data) => {
    return axiosClient.post('/employees', data);
  },
  createCompanyTerritory: (sid, data) => {
    return axiosClient.post(`/employees/${sid}/companies`, data);
  },
  getDetail: (sid) => {
    return axiosClient.get(`/employees/${sid}`);
  },
  update: ({ sid, ...data }) => {
    return axiosClient.put(`/employees/${sid}`, data);
  },
  updateAvatar: ({ sid, data }) => {
    return axiosClient.post(`/employees/${sid}/update-avatar`, data);
  },
  updateCompanyTerritory: ({ sid, id, ...data }) => {
    return axiosClient.put(`/employees/${sid}/companies/${id}`, data);
  },
  partialUpdate: ({ sid, ...data }) => {
    return axiosClient.patch(`/employees/${sid}`, data);
  },
  getListActivities: (params) => {
    return axiosClient.get(`/activities`, { params });
  },
  getListActivitiesFilter: (sid) => {
    return axiosClient.get(`/activity/employee/${sid}`);
  },
  deleteCompanyTerritory: (id) => {
    return axiosClient.delete(`/employees_to_company/${id}`);
  },
  deleteEmployeeLogout: (sid) => {
    return axiosClient.delete(`/employee/${sid}/logout`);
  },
  deleteEmployeeRegister: (sid) => {
    return axiosClient.post(`/delete/${sid}`);
  },
};

export default employeeApi;
