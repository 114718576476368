import axiosClient from './axiosClient';

const publicApi = {
  getLocation: ({ provinceCode, districtCode }) => {
    let url = '/public/locations/countries/VNM/provinces/';

    if (provinceCode) {
      url = `/public/locations/countries/VNM/provinces/${provinceCode}/districts/`;
    }

    if (districtCode) {
      url = `/public/locations/countries/VNM/provinces/${provinceCode}/districts/${districtCode}/wards/`;
    }

    return axiosClient.get(url);
  },
  getWeatherStatus: () => {
    return axiosClient.get(`/weather_status`);
  },
  getTimeOfDays: () => {
    return axiosClient.get(`/time_of_day`);
  },
  getMaterials: () => {
    return axiosClient.get(`/materials`);
  },
};

export default publicApi;
