export const GET_STORE_TYPE_PLANOGRAM_LIST =
  'features/project/GET_STORE_TYPE_PLANOGRAM_LIST';
export const GET_STORE_TYPE_PLANOGRAM_LIST_SUCCESS =
  'features/project/GET_STORE_TYPE_PLANOGRAM_LIST_SUCCESS';
export const GET_STORE_TYPE_PLANOGRAM_LIST_ERROR =
  'features/project/GET_STORE_TYPE_PLANOGRAM_LIST_ERROR';

export const CREATE_STORE_TYPE_PLANOGRAM =
  'features/project/CREATE_STORE_TYPE_PLANOGRAM';
export const CREATE_STORE_TYPE_PLANOGRAM_SUCCESS =
  'features/project/CREATE_STORE_TYPE_PLANOGRAM_SUCCESS';
export const CREATE_STORE_TYPE_PLANOGRAM_ERROR =
  'features/project/CREATE_STORE_TYPE_PLANOGRAM_ERROR';

export const GET_STORE_TYPE_PLANOGRAM_DETAIL =
  'features/project/GET_STORE_TYPE_PLANOGRAM_DETAIL';
export const GET_STORE_TYPE_PLANOGRAM_DETAIL_SUCCESS =
  'features/project/GET_STORE_TYPE_PLANOGRAM_DETAIL_SUCCESS';
export const GET_STORE_TYPE_PLANOGRAM_DETAIL_ERROR =
  'features/project/GET_STORE_TYPE_PLANOGRAM_DETAIL_ERROR';

export const UPDATE_STORE_TYPE_PLANOGRAM =
  'features/project/UPDATE_STORE_TYPE_PLANOGRAM';
export const UPDATE_STORE_TYPE_PLANOGRAM_SUCCESS =
  'features/project/UPDATE_STORE_TYPE_PLANOGRAM_SUCCESS';
export const UPDATE_STORE_TYPE_PLANOGRAM_ERROR =
  'features/project/UPDATE_STORE_TYPE_PLANOGRAM_ERROR';
