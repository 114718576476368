import { createGlobalStyle } from 'styled-components';
import 'antd/dist/antd.less';

const GlobalStyles = createGlobalStyle`
  html h1,
  html h2,
  html h3,
  html h4,
  html h5,
  html h6,
  html a,
  html p,
  html li,
  input,
  textarea,
  span,
  div,
  html,
  body,
  html a {
    margin-bottom: 0;
  }

  html ul, ol, dl {
    -webkit-padding-start: 0px;
    list-style: none;
    margin-bottom: 0;
  }

  body {
    margin: 0;
    font-family: ${(props) => props.theme.fonts.primary};
    color: ${(props) => props.theme.palette.text[0]};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }

  /*==========================
    NOTIFICATION
  ===========================*/
  .ant-notification-notice-with-icon {
    position: relative;
    color: #FFF;

    .ant-notification-notice-icon {
      left: 0;
      margin-left: 0;
      font-size: 30px;
      line-height: 30px;
      height: 30px;
      top: 50%;
      transform: translateY(-50%);
      color: inherit;
    }

    .ant-notification-notice-message {
      margin-bottom: 0;
      color: inherit;
    }
  }
  .ant-notification-notice-close {
    color: #FFF;
    &:hover {
      color: #FFF;
    }
  }

  /*==========================
    BUTTON
  ===========================*/
  .ant-btn {
    text-transform: uppercase;
  }
  .ant-popover-buttons button {
    text-transform: initial;
  }


  /*==========================
    TABLE
  ===========================*/
  .ant-table-container {
    overflow-x: auto;
  }
  .ant-table-thead {
    white-space: nowrap;
  }
  .ant-table table, .ant-table-thead > tr > th {
    text-align: center;
  }
  .ant-table-thead > tr
  .ant-table-tbody > tr > td {
    .imgTable {
      width: 250px;
      height: 150px;
      margin: 0 auto;
      position: relative;

      &::before {
        content: '';
        display: block;
        padding-top: 60%;
      }

      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  /*==========================
    SPIN
  ===========================*/
  .ant-spin-nested-loading > div > .ant-spin {
    max-height: 100%;
  }

  /*==========================
    UPLOAD
  ===========================*/
  .ant-upload.ant-upload-select-picture-card {
    margin-right: 0 !important;
    margin-bottom: 0 !important;
    width: 100% !important;
    background: white;


  }

  .ant-upload-list{
    display:inline-flex;
    background-color:white;
  }

  .upload-list-inline .ant-upload-list-item {
  float: left;
  width:100px;
  margin-right: 8px;
}

.upload-list-inline [class*='-upload-list-rtl'] .ant-upload-list-item {
  float: right;
}

  /*==========================
    TAG
  ===========================*/
  .ant-tag {
    font-size: 14px;
    margin-right: 0;
  }

  /*==========================
    SPACE
  ===========================*/
  .ant-space {
    flex-wrap: wrap;
  }

  /*==========================
    FORM
  ===========================*/
  .ant-form-vertical .ant-form-item-label {
    padding: 0;
  }

  /*==========================
    COLLAPSE
  ===========================*/
  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    display: flex;
    align-items: center;
  }
  .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-extra {
    margin-left: auto;
  }

  .ant-transfer-list {
    // width: 500px;

  }
  .ant-col-md-10{
    width:100%;
    max-width:unset
  }
  .menu-dropdown-photo{
    color:white;
    &:hover{
      span{
        color:white !important;
        opacity : 1 !important;
      }
    }
  }
  .ant-menu-horizontal{
    line-height:40px;
  }
  .ant-menu-submenu-selected{
    color:white !important;
    border-bottom:none !important;
  }
  .ant-menu-submenu-title{
    &:hover{
      span{
        color:white !important;
      }
    }
  }

  /* .ant-menu-submenu-title{
    &:hover{
      color: white !important;
    }
  } */
  .stoneBridgeLabs_title{
    & + & {
      margin-top:1rem
    }
    .ant-table-title{
      color:#fff;
      background-color:#4482FF;
      font-weight:bold;
    }
  }

  .table-striped-rows tr:nth-child(2n) td {
    background-color: #fbfbfb;
  }
  .table-striped-rows thead {
    background-color: #f1f1f1;
  }
  .table-row-light {
    background-color: #ffffff;
  }
  .table-row-dark {
    background-color: #fbfbfb;
  }

  .noChange{
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */

  }

`;

export default GlobalStyles;
