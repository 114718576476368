import { takeEvery, call } from 'redux-saga/effects';
import * as actionTypes from './actionTypes';
import { importApi } from '../../api/importExportApi';

function* handleDownloadTemplate({ payload }) {
  const { params, callback } = payload;

  try {
    const result = yield call(importApi.downloadTemplate, params);

    callback({
      error: false,
      data: result,
    });
  } catch (error) {
    callback({ error: true, errors: error.errors });
  }
}

function* handleCreateImport({ payload }) {
  const { data, callback } = payload;

  try {
    const result = yield call(importApi.create, data);

    callback({
      error: false,
      data: result,
    });
  } catch (error) {
    callback({ error: true, errors: error.errors });
  }
}

function* handleGetImport({ payload }) {
  const { params, callback } = payload;

  try {
    const result = yield call(importApi.getList, params);

    callback({ error: false, data: result });
  } catch (error) {
    callback({ error: true, errors: error.errors });
  }
}

export default [
  takeEvery(actionTypes.DOWNLOAD_TEMPLATE, handleDownloadTemplate),
  takeEvery(actionTypes.CREATE_IMPORT, handleCreateImport),
  takeEvery(actionTypes.GET_IMPORT, handleGetImport),
];
