import React from 'react';
import styled from 'styled-components';
import { Pagination } from 'antd';

const FooterTableWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-flow: row wrap;
`;

export default function FooterTable({ selectedRowKeys = [], ...otherProps }) {
  return (
    <FooterTableWrapper>
      <p>{selectedRowKeys.length ? `Đã chọn ${selectedRowKeys.length}` : ''}</p>
      <Pagination
        {...otherProps}
        // total={total}
        showTotal={(total) => `Tổng ${total}`}
      />
    </FooterTableWrapper>
  );
}
