import axiosClient from './axiosClient';

const imageCategoryApi = {
  getList: (params) => {
    return axiosClient.get('/admin/image-categories/', { params });
  },
  create: (data) => {
    return axiosClient.post('/admin/image-categories/', data);
  },
  getDetail: (sid) => {
    return axiosClient.get(`/admin/image-categories/${sid}/`);
  },
  update: ({ sid, ...data }) => {
    return axiosClient.put(`/admin/image-categories/${sid}/`, data);
  },
  partialUpdate: ({ sid, ...data }) => {
    return axiosClient.patch(`/admin/image-categories/${sid}/`, data);
  },
};

export default imageCategoryApi;
