export const GET_REGION_LIST = 'features/project/GET_REGION_LIST';
export const GET_REGION_LIST_SUCCESS =
  'features/project/GET_REGION_LIST_SUCCESS';
export const GET_REGION_LIST_ERROR = 'features/project/GET_REGION_LIST_ERROR';

export const CREATE_REGION = 'features/project/CREATE_REGION';
export const CREATE_REGION_SUCCESS = 'features/project/CREATE_REGION_SUCCESS';
export const CREATE_REGION_ERROR = 'features/project/CREATE_REGION_ERROR';

export const GET_REGION_DETAIL = 'features/project/GET_REGION_DETAIL';
export const GET_REGION_DETAIL_SUCCESS =
  'features/project/GET_REGION_DETAIL_SUCCESS';
export const GET_REGION_DETAIL_ERROR =
  'features/project/GET_REGION_DETAIL_ERROR';

export const UPDATE_REGION = 'features/project/UPDATE_REGION';
export const UPDATE_REGION_SUCCESS = 'features/project/UPDATE_REGION_SUCCESS';
export const UPDATE_REGION_ERROR = 'features/project/UPDATE_REGION_ERROR';
