export const GET_LIST = 'features/jobs/GET_LIST';
export const GET_LIST_SUCCESS = 'features/jobs/GET_LIST_SUCCESS';
export const GET_LIST_ERROR = 'features/jobs/GET_LIST_ERROR';

export const GET_LIST_PHOTO_FILTER = 'features/jobs/GET_LIST_PHOTO_FILTER';
export const GET_LIST_PHOTO_FILTER_SUCCESS =
  'features/jobs/GET_LIST_PHOTO_FILTER_SUCCESS';
export const GET_LIST_PHOTO_FILTER_ERROR =
  'features/jobs/GET_LIST_PHOTO_FILTER_ERROR';

export const GET_LIST_PHOTO_JOB_STORE_FILTER =
  'features/jobs/GET_LIST_PHOTO_JOB_STORE_FILTER';
export const GET_LIST_PHOTO_JOB_STORE_FILTER_SUCCESS =
  'features/jobs/GET_LIST_PHOTO_JOB_STORE_FILTER_SUCCESS';
export const GET_LIST_PHOTO_JOB_STORE_FILTER_ERROR =
  'features/jobs/GET_LIST_PHOTO_JOB_STORE_FILTER_ERROR';

export const GET_LIST_JOB_STORE = 'features/jobs/GET_LIST_JOB_STORE';
export const GET_LIST_JOB_STORE_SUCCESS =
  'features/jobs/GET_LIST_JOB_STORE_SUCCESS';
export const GET_LIST_JOB_STORE_ERROR =
  'features/jobs/GET_LIST_JOB_STORE_ERROR';

export const GET_LIST_JOB_PLAN = 'features/jobs/GET_LIST_JOB_PLAN';
export const GET_LIST_JOB_PLAN_SUCCESS =
  'features/jobs/GET_LIST_JOB_PLAN_SUCCESS';
export const GET_LIST_JOB_PLAN_ERROR = 'features/jobs/GET_LIST_JOB_PLAN_ERROR';

export const CREATE = 'features/jobs/CREATE';
export const CREATE_SUCCESS = 'features/jobs/CREATE_SUCCESS';
export const CREATE_ERROR = 'features/jobs/CREATE_ERROR';

export const EXPORT = 'features/jobs/EXPORT';
export const EXPORT_SUCCESS = 'features/jobs/EXPORT_SUCCESS';
export const EXPORT_ERROR = 'features/jobs/EXPORT_ERROR';

export const DOWNLOAD = 'features/jobs/DOWNLOAD';
export const DOWNLOAD_SUCCESS = 'features/jobs/DOWNLOAD_SUCCESS';
export const DOWNLOAD_ERROR = 'features/jobs/DOWNLOAD_ERROR';

export const GET_DETAIL = 'features/jobs/GET_DETAIL';
export const GET_DETAIL_SUCCESS = 'features/jobs/GET_DETAIL_SUCCESS';
export const GET_DETAIL_ERROR = 'features/jobs/GET_DETAIL_ERROR';

export const UPDATE = 'features/jobs/UPDATE';
export const UPDATE_SUCCESS = 'features/jobs/UPDATE_SUCCESS';
export const UPDATE_ERROR = 'features/jobs/UPDATE_ERROR';

export const PLAN = 'features/jobs/PLAN';
export const PLAN_SUCCESS = 'features/jobs/PLAN_SUCCESS';
export const PLAN_ERROR = 'features/jobs/PLAN_ERROR';

export const STORE_JOB_UPLOAD_FILE = 'features/jobs/STORE_JOB_UPLOAD_FILE';
export const STORE_JOB_UPLOAD_FILE_SUCCESS =
  'features/jobs/STORE_JOB_UPLOAD_FILE_SUCCESS';
export const STORE_JOB_UPLOAD_FILE_ERROR =
  'features/jobs/STORE_JOB_UPLOAD_FILE_ERROR';

export const GET_STORE_JOB_UPLOAD_FILE =
  'features/jobs/GET_STORE_JOB_UPLOAD_FILE';
export const GET_STORE_JOB_UPLOAD_FILE_SUCCESS =
  'features/jobs/GET_STORE_JOB_UPLOAD_FILE_SUCCESS';
export const GET_STORE_JOB_UPLOAD_FILE_ERROR =
  'features/jobs/GET_STORE_JOB_UPLOAD_FILE_ERROR';

export const GET_LIST_FIELDS = 'features/jobs/GET_LIST_FIELDS';
export const GET_LIST_FIELDS_SUCCESS = 'features/jobs/GET_LIST_FIELDS_SUCCESS';
export const GET_LIST_FIELDS_ERROR = 'features/jobs/GET_LIST_FIELDS_ERROR';

export const UPDATE_STORE_IN_JOB = 'feature/jobs/UPDATE_STORE_IN_JOB';
export const UPDATE_STORE_IN_JOB_SUCCESS =
  'feature/jobs/UPDATE_STORE_IN_JOB_SUCCESS';
export const UPDATE_STORE_IN_JOB_ERROR =
  'feature/jobs/UPDATE_STORE_IN_JOB_ERROR';

export const SET_PARAMS = 'features/jobs/SET_PARAMS';

export const GET_WEATHER_STATUS = 'features/jobs/GET_WEATHER_STATUS';
export const GET_WEATHER_STATUS_SUCCESS =
  'features/jobs/GET_WEATHER_STATUS_SUCCESS';
export const GET_WEATHER_STATUS_ERROR =
  'features/jobs/GET_WEATHER_STATUS_ERROR';

export const GET_TIME_OF_DAYS = 'features/jobs/GET_TIME_OF_DAYS';
export const GET_TIME_OF_DAYS_SUCCESS =
  'features/jobs/GET_TIME_OF_DAYS_SUCCESS';
export const GET_TIME_OF_DAYS_ERROR = 'features/jobs/GET_TIME_OF_DAYS_ERROR';

export const GET_MATERIALS = 'features/jobs/GET_MATERIALS';
export const GET_MATERIALS_SUCCESS = 'features/jobs/GET_MATERIALS_SUCCESS';
export const GET_MATERIALS_ERROR = 'features/jobs/GET_MATERIALS_ERROR';

export const UPDATE_EDIT_JOB = 'features/jobs/UPDATE_EDIT_JOB';
export const UPDATE_EDIT_JOB_SUCCESS = 'features/jobs/UPDATE_EDIT_JOB_SUCCESS';
export const UPDATE_EDIT_JOB_ERROR = 'features/jobs/UPDATE_EDIT_JOB_ERROR';

// export const SET_PARAMS = 'features/jobs/SET_PARAMS';
