export const GET_LIST = 'features/imageCategory/GET_LIST';
export const GET_LIST_SUCCESS = 'features/imageCategory/GET_LIST_SUCCESS';
export const GET_LIST_ERROR = 'features/imageCategory/GET_LIST_ERROR';

export const CREATE = 'features/imageCategory/CREATE';
export const CREATE_SUCCESS = 'features/imageCategory/CREATE_SUCCESS';
export const CREATE_ERROR = 'features/imageCategory/CREATE_ERROR';

export const GET_DETAIL = 'features/imageCategory/GET_DETAIL';
export const GET_DETAIL_SUCCESS = 'features/imageCategory/GET_DETAIL_SUCCESS';
export const GET_DETAIL_ERROR = 'features/imageCategory/GET_DETAIL_ERROR';

export const UPDATE = 'features/imageCategory/UPDATE';
export const UPDATE_SUCCESS = 'features/imageCategory/UPDATE_SUCCESS';
export const UPDATE_ERROR = 'features/imageCategory/UPDATE_ERROR';

export const SET_PARAMS = 'features/imageCategory/SET_PARAMS';
