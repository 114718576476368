import { takeEvery, call } from 'redux-saga/effects';
import * as actionTypes from './actionTypes';
import { skuPlanogramApi } from '../../../../api/projectApi';

function* handleGetSkuPlanogramList({ payload }) {
  const { projectSid, planogramSid, params, callback } = payload;

  try {
    const result = yield call(
      skuPlanogramApi.getList,
      projectSid,
      planogramSid,
      params,
    );

    callback({ error: false, data: result });
  } catch (error) {
    callback({ error: true, errors: error.errors });
  }
}

function* handleCreateSkuPlanogram({ payload }) {
  const { data, callback } = payload;

  try {
    const result = yield call(skuPlanogramApi.create, data);

    callback({ error: false, data: result });
  } catch (error) {
    callback({ error: true, errors: error.errors });
  }
}

function* handleGetSkuPlanogramDetail({ payload }) {
  const { data, callback } = payload;

  try {
    const result = yield call(skuPlanogramApi.getDetail, data);

    callback({ error: false, data: result });
  } catch (error) {
    callback({ error: true, errors: error.errors });
  }
}

function* handleUpdateSkuPlanogram({ payload }) {
  const { data, callback } = payload;

  try {
    const result = yield call(skuPlanogramApi.update, data);

    callback({ error: false, data: result });
  } catch (error) {
    callback({ error: true, errors: error.errors });
  }
}

export default [
  takeEvery(actionTypes.GET_SKU_PLANOGRAM_LIST, handleGetSkuPlanogramList),
  takeEvery(actionTypes.CREATE_SKU_PLANOGRAM, handleCreateSkuPlanogram),
  takeEvery(actionTypes.GET_SKU_PLANOGRAM_DETAIL, handleGetSkuPlanogramDetail),
  takeEvery(actionTypes.UPDATE_SKU_PLANOGRAM, handleUpdateSkuPlanogram),
];
