import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import createSagaMiddleware from 'redux-saga';
import { routerMiddleware } from 'connected-react-router';
import { createLogger } from 'redux-logger';
import { persistStore } from 'redux-persist';

import createRootReducer from './rootReducer';
import rootSaga from './rootSaga';

export default function configureStore(initialState = {}, history) {
  // If Redux DevTools Extension is installed use it, otherwise use Redux compose
  let composeEnhancers = compose;

  // Create the store with three middlewares
  // 1. thunk: Makes redux-thunk work
  // 2. sagaMiddleware: Makes redux-sagas work
  // 3. routerMiddleware: Syncs the location/URL path to the state
  // 4. logger: Makes redux-logger work (Log only in development)
  const sagaMiddleware = createSagaMiddleware();
  const middlewares = [thunk, sagaMiddleware, routerMiddleware(history)];

  if (
    process.env.NODE_ENV !== 'production' &&
    typeof window === 'object' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
  ) {
    const logger = createLogger({ collapsed: true });
    middlewares.push(logger);
    composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({});
  }

  const enhancers = [applyMiddleware(...middlewares)]; // Spread in function

  const store = createStore(
    createRootReducer(history),
    initialState,
    composeEnhancers(...enhancers),
  );

  const persistor = persistStore(store);

  sagaMiddleware.run(rootSaga);

  return {
    store,
    persistor,
  };
}
