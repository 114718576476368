import { takeEvery, call } from 'redux-saga/effects';
import * as actionTypes from './actionTypes';
import { regionApi } from '../../../../api/projectApi';

function* handleGetRegionList({ payload }) {
  const { projectSid, params, callback } = payload;

  try {
    const result = yield call(regionApi.getList, projectSid, params);

    callback({ error: false, data: result });
  } catch (error) {
    callback({ error: true, errors: error.errors });
  }
}

function* handleCreateRegion({ payload }) {
  const { data, callback } = payload;

  try {
    const result = yield call(regionApi.create, data);

    callback({ error: false, data: result });
  } catch (error) {
    callback({ error: true, errors: error.errors });
  }
}

function* handleGetRegionDetail({ payload }) {
  const { data, callback } = payload;

  try {
    const result = yield call(regionApi.getDetail, data);

    callback({ error: false, data: result });
  } catch (error) {
    callback({ error: true, errors: error.errors });
  }
}

function* handleUpdateRegion({ payload }) {
  const { data, callback } = payload;

  try {
    const result = yield call(regionApi.update, data);

    callback({ error: false, data: result });
  } catch (error) {
    callback({ error: true, errors: error.errors });
  }
}

export default [
  takeEvery(actionTypes.GET_REGION_LIST, handleGetRegionList),
  takeEvery(actionTypes.CREATE_REGION, handleCreateRegion),
  takeEvery(actionTypes.GET_REGION_DETAIL, handleGetRegionDetail),
  takeEvery(actionTypes.UPDATE_REGION, handleUpdateRegion),
];
