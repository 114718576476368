import { takeEvery, call } from 'redux-saga/effects';
import * as actionTypes from './actionTypes';
import jobsApi from '../../api/jobApi';
import publicApi from '../../api/publicApi';
function* handleGetList({ payload }) {
  const { params, callback } = payload;

  try {
    const result = yield call(jobsApi.getList, params);

    callback({ error: false, data: result });
  } catch (error) {
    callback({ error: true, errors: error.errors });
  }
}

function* handleGetListJobStore({ payload }) {
  const { sid, params, callback } = payload;
  try {
    const result = yield call(jobsApi.getListJobStore, sid, params);
    callback({ error: false, data: result });
  } catch (error) {
    callback({ error: true, errors: error.errors });
  }
}
function* handleGetListJobPlan({ payload }) {
  const { plan_sid, params, callback } = payload;

  try {
    const result = yield call(jobsApi.getListJobPlan, plan_sid, params);
    callback({ error: false, data: result });
  } catch (error) {
    callback({ error: true, errors: error.errors });
  }
}

function* handleGetListPhotoFilter({ payload }) {
  const { params, callback } = payload;

  try {
    const result = yield call(jobsApi.getFilterListPhoto, params);
    callback({ error: false, data: result });
  } catch (error) {
    callback({ error: true, errors: error.errors });
  }
}

function* handleGetListPhotoJobStoreFilter({ payload }) {
  const { params, callback } = payload;

  try {
    const result = yield call(jobsApi.getFilterListPhotoJobStore, params);
    callback({ error: false, data: result });
  } catch (error) {
    callback({ error: true, errors: error.errors });
  }
}

function* handleCreate({ payload }) {
  const { data, callback } = payload;

  try {
    const result = yield call(jobsApi.create, data);

    callback({ error: false, data: result });
  } catch (error) {
    callback({ error: true, errors: error.errors });
  }
}

function* handleExport({ payload }) {
  const { job_sid, plan_sid, data, callback } = payload;
  try {
    const result = yield call(jobsApi.export, job_sid, plan_sid, data);

    callback({ error: false, data: result });
  } catch (error) {
    callback({ error: true, errors: error.errors });
  }
}

function* handleDownload({ payload }) {
  const { job_sid, plan_sid, data, callback } = payload;
  try {
    const result = yield call(jobsApi.download, job_sid, plan_sid, data);

    callback({ error: false, data: result });
  } catch (error) {
    callback({ error: true, errors: error.errors });
  }
}

function* handleGetDetail({ payload }) {
  const { sid, callback } = payload;

  try {
    const result = yield call(jobsApi.getDetail, sid);

    callback({ error: false, data: result });
  } catch (error) {
    callback({ error: true, errors: error.errors });
  }
}

function* handleUpdate({ payload }) {
  const { data, callback } = payload;

  try {
    const result = yield call(jobsApi.update, data);

    callback({ error: false, data: result });
  } catch (error) {
    callback({ error: true, errors: error.errors });
  }
}

function* handleUpdatePlan({ payload }) {
  const { data, callback } = payload;

  try {
    const result = yield call(jobsApi.plan, data);

    callback({ error: false, data: result });
  } catch (error) {
    callback({ error: true, errors: error.errors });
  }
}

function* handleStoreJobUploadFile({ payload }) {
  const { data, callback } = payload;
  try {
    const result = yield call(jobsApi.storeBojUploadFile, data);
    callback({ error: false, data: result });
  } catch (error) {
    callback({ error: true, errors: error.errors });
  }
}

function* handleGetStoreJobUploadFile({ payload }) {
  const { data, job_sid, callback } = payload;
  try {
    const result = yield call(jobsApi.getUploadJobStoreEdit, {
      data,
      job_sid,
    });
    callback({ error: false, data: result });
  } catch (error) {
    callback({ error: true, errors: error.errors });
  }
}
function* handleGetListFields({ payload }) {
  const { params, callback } = payload;

  try {
    const result = yield call(jobsApi.getListFields, params);

    callback({ error: false, data: result });
  } catch (error) {
    callback({ error: true, errors: error.errors });
  }
}

function* handleUpdateStoreInJob({ payload }) {
  const { data, callback, job_sid } = payload;

  try {
    const result = yield call(jobsApi.updateJobStore, job_sid, data);
    callback({ error: false, data: result });
  } catch (error) {
    callback({ error: true, errors: error.errors });
  }
}
function* handleGetWeatherStatus({ payload }) {
  const { callback } = payload;

  try {
    const result = yield call(publicApi.getWeatherStatus);

    callback({ error: false, data: result });
  } catch (error) {
    callback({ error: true, errors: error.errors });
  }
}
function* handleGetTimeOfDays({ payload }) {
  const { callback } = payload;

  try {
    const result = yield call(publicApi.getTimeOfDays);

    callback({ error: false, data: result });
  } catch (error) {
    callback({ error: true, errors: error.errors });
  }
}
function* handleGetMaterials({ payload }) {
  const { callback } = payload;

  try {
    const result = yield call(publicApi.getMaterials);

    callback({ error: false, data: result });
  } catch (error) {
    callback({ error: true, errors: error.errors });
  }
}

function* handleUpdateEditJob({ payload }) {
  const { data, callback, plan_sid, data_diff } = payload;

  try {
    const result = yield call(jobsApi.updateEditJobStore, { data, plan_sid, data_diff });

    callback({ error: false, data: result });
  } catch (error) {
    callback({ error: true, errors: error.errors });
  }
}

export default [
  takeEvery(actionTypes.GET_LIST, handleGetList),
  takeEvery(actionTypes.GET_LIST_JOB_STORE, handleGetListJobStore),
  takeEvery(actionTypes.GET_LIST_PHOTO_FILTER, handleGetListPhotoFilter),
  takeEvery(
    actionTypes.GET_LIST_PHOTO_JOB_STORE_FILTER,
    handleGetListPhotoJobStoreFilter,
  ),
  takeEvery(actionTypes.GET_LIST_JOB_PLAN, handleGetListJobPlan),
  takeEvery(actionTypes.CREATE, handleCreate),
  takeEvery(actionTypes.EXPORT, handleExport),
  takeEvery(actionTypes.DOWNLOAD, handleDownload),
  takeEvery(actionTypes.GET_DETAIL, handleGetDetail),
  takeEvery(actionTypes.UPDATE, handleUpdate),
  takeEvery(actionTypes.PLAN, handleUpdatePlan),
  takeEvery(actionTypes.STORE_JOB_UPLOAD_FILE, handleStoreJobUploadFile),
  takeEvery(actionTypes.GET_STORE_JOB_UPLOAD_FILE, handleGetStoreJobUploadFile),
  takeEvery(actionTypes.GET_LIST_FIELDS, handleGetListFields),
  takeEvery(actionTypes.UPDATE_STORE_IN_JOB, handleUpdateStoreInJob),
  takeEvery(actionTypes.GET_WEATHER_STATUS, handleGetWeatherStatus),
  takeEvery(actionTypes.GET_TIME_OF_DAYS, handleGetTimeOfDays),
  takeEvery(actionTypes.GET_MATERIALS, handleGetMaterials),
  takeEvery(actionTypes.UPDATE_EDIT_JOB, handleUpdateEditJob),
];
