export const DOWNLOAD_TEMPLATE = 'features/import/DOWNLOAD_TEMPLATE';
export const DOWNLOAD_TEMPLATE_SUCCESS =
  'features/import/DOWNLOAD_TEMPLATE_SUCCESS';
export const DOWNLOAD_TEMPLATE_ERROR =
  'features/import/DOWNLOAD_TEMPLATE_ERROR';

export const GET_IMPORT = 'features/import/GET_IMPORT';
export const GET_IMPORT_SUCCESS = 'features/import/GET_IMPORT_SUCCESS';
export const GET_IMPORT_ERROR = 'features/import/GET_IMPORT_ERROR';

export const CREATE_IMPORT = 'features/import/CREATE_IMPORT';
export const CREATE_IMPORT_SUCCESS = 'features/import/CREATE_IMPORT_SUCCESS';
export const CREATE_IMPORT_ERROR = 'features/import/CREATE_IMPORT_ERROR';
