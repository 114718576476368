import axiosClient from './axiosClient';

const dashboardApi = {
  getChartList: (job_sid) => {
    return axiosClient.post('/dashboard/territory', { job_sid });
  },
  getTerritoryByJob: (job_sid) => {
    return axiosClient.post('/territory_by_job', { job_sid });
  },
  getStoreChat: (job_sid, territory_sid) => {
    return axiosClient.post('/dashboard/store', { job_sid, territory_sid });
  },
};

export default dashboardApi;
