import { takeEvery, call } from 'redux-saga/effects';
import * as actionTypes from './actionTypes';
import companyApi from '../../api/companyApi';

function* handleGetList({ payload }) {
  const { params, callback } = payload;

  try {
    const result = yield call(companyApi.getList, params);

    callback({ error: false, data: result });
  } catch (error) {
    callback({ error: true, errors: error.errors });
  }
}

function* handleGetListFull({ payload }) {
  const { params, callback } = payload;

  try {
    const result = yield call(companyApi.getListCompanyFull, params);

    callback({ error: false, data: result });
  } catch (error) {
    callback({ error: true, errors: error.errors });
  }
}
// function* handleGetListCityState({ payload }) {
//   const { params, callback } = payload;

//   try {
//     const result = yield call(companyApi.getList, params);

//     callback({ error: false, data: result });
//   } catch (error) {
//     callback({ error: true, errors: error.errors });
//   }
// }

function* handleGetListCompanyTerritory({ payload }) {
  const { sid, params, callback } = payload;

  try {
    const result = yield call(companyApi.getTerritoryCompany, sid, params);

    callback({ error: false, data: result });
  } catch (error) {
    callback({ error: true, errors: error.errors });
  }
}

function* handleCreate({ payload }) {
  const { data, callback } = payload;

  try {
    const result = yield call(companyApi.create, data);

    callback({ error: false, data: result });
  } catch (error) {
    callback({ error: true, errors: error.errors });
  }
}

function* handleGetDetail({ payload }) {
  const { sid, callback } = payload;

  try {
    const result = yield call(companyApi.getDetail, sid);

    callback({ error: false, data: result });
  } catch (error) {
    callback({ error: true, errors: error.errors });
  }
}

function* handleUpdate({ payload }) {
  const { data, callback } = payload;

  try {
    const result = yield call(companyApi.update, data);

    callback({ error: false, data: result });
  } catch (error) {
    callback({ error: true, errors: error.errors });
  }
}

export default [
  takeEvery(actionTypes.GET_LIST, handleGetList),
  takeEvery(actionTypes.GET_LIST_FULL, handleGetListFull),
  takeEvery(actionTypes.CREATE, handleCreate),
  takeEvery(actionTypes.GET_DETAIL, handleGetDetail),
  takeEvery(actionTypes.UPDATE, handleUpdate),
  takeEvery(
    actionTypes.GET_LIST_COMPANY_TERRITORY,
    handleGetListCompanyTerritory,
  ),
];
