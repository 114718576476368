import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import App from '../containers/Dashboard/reducers';
import auth from '../features/login/reducers';
import demoReducer from '../features/demo/reducers';
// import publicReducer from '../features/public/reducers';
import dashboardReducer from '../features/dashboard/reducers'
import companyReducer from '../features/company/reducers';
import storeReducer from '../features/store/reducers';
import employeeReducer from '../features/employee/reducers';
import projectReducer from '../features/project/reducers';
import imageCategoryReducer from '../features/imageCategory/reducers';
import teritoryReducer from '../features/territory/reducers';
import myProfileReducer from '../features/myProfile/reducers';
import jobReducer from '../features/job/reducers';
import photoReducer from '../features/photo/reducers';
import publicReducer from '../app/reducers';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: [],
  // blacklist: ['router'],
};

export default function createRootReducer(history) {
  const rootReducer = combineReducers({
    App,
    auth,
    demoReducer,
    dashboard: dashboardReducer,
    company: companyReducer,
    store: storeReducer,
    employee: employeeReducer,
    project: projectReducer,
    imageCategory: imageCategoryReducer,
    router: connectRouter(history),
    teritory: teritoryReducer,
    myProfile: myProfileReducer,
    job: jobReducer,
    public: publicReducer,
    photo: photoReducer,
  });

  // return rootReducer;
  return persistReducer(persistConfig, rootReducer);
}
