export const GET_LIST = 'features/photo/GET_LIST';
export const GET_LIST_SUCCESS = 'features/photo/GET_LIST_SUCCESS';
export const GET_LIST_ERROR = 'features/photo/GET_LIST_ERROR';

export const DELETE = 'features/photo/DELETE';
export const DELETE_SUCCESS = 'features/photo/DELETE_SUCCESS';
export const DELETE_ERROR = 'features/photo/DELETE_ERROR';

export const DOWNLOAD = 'features/photo/DOWNLOAD';
export const DOWNLOAD_SUCCESS = 'features/photo/DOWNLOAD_SUCCESS';
export const DOWNLOAD_ERROR = 'features/photo/DOWNLOAD_ERROR';

export const CREATE = 'features/photo/CREATE';
export const CREATE_SUCCESS = 'features/photo/CREATE_SUCCESS';
export const CREATE_ERROR = 'features/photo/CREATE_ERROR';

export const GET_LIST_JOBS_OF_COMPANY =
  'features/photo/GET_LIST_JOBS_OF_COMPANY';

export const GET_LIST_STORES_OF_COMPANY =
  'features/photo/GET_LIST_STORES_OF_COMPANY';

export const GET_LIST_PHOTO_ELEVATIONS =
  'features/photo/GET_LIST_PHOTO_ELEVATIONS';

export const GET_LIST_PHOTO_TAG = 'features/photo/GET_LIST_PHOTO_TAG';

export const GET_DETAIL = 'features/photo/GET_DETAIL';
export const GET_DETAIL_SUCCESS = 'features/photo/GET_DETAIL_SUCCESS';
export const GET_DETAIL_ERROR = 'features/photo/GET_DETAIL_ERROR';

export const UPDATE = 'features/photo/UPDATE';
export const UPDATE_SUCCESS = 'features/photo/UPDATE_SUCCESS';
export const UPDATE_ERROR = 'features/photo/UPDATE_ERROR';

export const GET_PHOTO_INTERIOR = 'features/photo/GET_PHOTO_INTERIOR';
export const GET_PHOTO_INTERIOR_SUCCESS = 'features/photo/GET_PHOTO_INTERIOR_SUCCESS';
export const GET_PHOTO_INTERIOR_ERROR = 'features/photo/GET_PHOTO_INTERIOR_ERROR';

export const SET_PARAMS = 'features/photo/SET_PARAMS';
