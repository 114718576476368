import { takeEvery, call } from 'redux-saga/effects';
import * as actionTypes from './actionTypes';
import { storeTypeApi } from '../../../../api/projectApi';

function* handleGetStoreTypeList({ payload }) {
  const { projectSid, params, callback } = payload;

  try {
    const result = yield call(storeTypeApi.getList, projectSid, params);

    callback({ error: false, data: result });
  } catch (error) {
    callback({ error: true, errors: error.errors });
  }
}

function* handleCreateStoreType({ payload }) {
  const { data, callback } = payload;

  try {
    const result = yield call(storeTypeApi.create, data);

    callback({ error: false, data: result });
  } catch (error) {
    callback({ error: true, errors: error.errors });
  }
}

function* handleGetStoreTypeDetail({ payload }) {
  const { data, callback } = payload;

  try {
    const result = yield call(storeTypeApi.getDetail, data);

    callback({ error: false, data: result });
  } catch (error) {
    callback({ error: true, errors: error.errors });
  }
}

function* handleUpdateStoreType({ payload }) {
  const { data, callback } = payload;

  try {
    const result = yield call(storeTypeApi.update, data);

    callback({ error: false, data: result });
  } catch (error) {
    callback({ error: true, errors: error.errors });
  }
}

export default [
  takeEvery(actionTypes.GET_STORE_TYPE_LIST, handleGetStoreTypeList),
  takeEvery(actionTypes.CREATE_STORE_TYPE, handleCreateStoreType),
  takeEvery(actionTypes.GET_STORE_TYPE_DETAIL, handleGetStoreTypeDetail),
  takeEvery(actionTypes.UPDATE_STORE_TYPE, handleUpdateStoreType),
];
