import queryString from 'query-string';
import _ from 'lodash';
import moment from 'moment';

export const formatFullTime = (time, format = 'DD-MM-YYYY HH:mm:ss') => {
  return moment(time).format(format);
};

// Compare 2 objects with keys
export const compareObjWithKeys = (
  [...keyCompare],
  { ...object1 },
  { ...object2 },
) => {
  const newObj1WithKeyCompare = _.pick(object1, keyCompare);
  const newObj2WithKeyCompare = _.pick(object2, keyCompare);

  return _.isEqual(newObj1WithKeyCompare, newObj2WithKeyCompare);
};

export const convertQueryString = (obj) => {
  return queryString.stringify(obj, {
    skipNull: true,
    skipEmptyString: true,
  });
};

export const parseJwt = (token) => {
  try {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map((c) => {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join(''),
    );

    return JSON.parse(jsonPayload);
  } catch (error) {
    return null;
  }
};

export const convertObjToQueryString = (queryObj) => {
  const queryArr = [];

  for (let i in queryObj) {
    if (queryObj[i]) {
      queryArr.push(`${i}=${queryObj[i]}`);
    }
  }

  return queryArr.join('&');
};

/**
 * **************** VALIDATE FOR ANTD
 */

export const checkSpecifiedRadius = (rule, value) => {
  // const pattern = /^\d+$/;
  const pattern = /^-?\d*\d+$/;
  if (!value) {
    return Promise.reject();
  } else {
    if (pattern.test(value)) {
      if (value <= 2147483647 && value >= -2147483648) {
        return Promise.resolve();
      }

      return Promise.reject(
        'Please must be between -2147483648 and 2147483647',
      );
    }

    return Promise.reject('Please must be a integer number');
  }
};

export const subMonthFromNow = (num_of_month) => {
  const options = [];

  for (let i = 0; i < num_of_month; i++) {
    let event = new Date();
    event.setMonth(event.getMonth() - i);
    options.push({
      label: event.getMonth() + 1 + '/' + event.getFullYear(),
      value: event.toISOString(),
    });
  }

  return options;
};

export const getFullAddress = (value) => {
  const addressArr = [];

  if (value.address || value.location) {
    addressArr.push(value.address || value.location);
  }

  if (value.ward) {
    addressArr.push(value.ward.display_name);
  }

  if (value.district) {
    addressArr.push(value.district.display_name);
  }

  if (value.province) {
    addressArr.push(value.province.display_name);
  }

  return addressArr.join(', ');
};

export const calcCurrentPage = (currentPage, pageSize, total, data) => {
  const calcPage = Math.ceil((total - data) / pageSize) || 1; // Trường hợp xóa hết ==> 1

  return calcPage >= currentPage ? currentPage : calcPage;
};

export const increaseADate = () => {
  let tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);

  return tomorrow;
};

// *********************8

export const addIndex = (arr) => {
  return arr.map((item, index) => ({
    ...item,
    index,
  }));
};

export const getFullTime = (d) => {
  if (!d) {
    return {
      full: null,
      date: null,
      time: null,
    };
  }

  const newDate = new Date(d);
  const year = `${newDate.getFullYear()}`;
  let month = `${newDate.getMonth() + 1}`;
  let date = `${newDate.getDate()}`;
  let hour = `${newDate.getHours()}`;
  let min = `${newDate.getMinutes()}`;
  let sec = `${newDate.getSeconds()}`;

  if (month.length === 1) {
    month = `0${month}`;
  }
  if (date.length === 1) {
    date = `0${date}`;
  }
  if (hour.length === 1) {
    hour = `0${hour}`;
  }
  if (min.length === 1) {
    min = `0${min}`;
  }
  if (sec.length === 1) {
    sec = `0${sec}`;
  }

  return {
    full: `${date}-${month}-${year} ${hour}:${min}:${sec}`,
    date: `${date}-${month}-${year}`,
    time: `${hour}:${min}:${sec}`,
  };
};

export const getLocation = ({ city, state }) => {
  const result = [];
  if (city) {
    result.push(city);
  }
  if (state) {
    result.push(state);
  }
  return result.join(', ');
};
