import axiosClient from './axiosClient';

const teritoryApi = {
  getList: (params) => {
    return axiosClient.get('/territories', { params });
  },
  create: (data) => {
    return axiosClient.post('/territories', data);
  },
  getDetail: (sid) => {
    return axiosClient.get(`/territories/${sid}`);
  },
  update: ({ sid, ...data }) => {
    return axiosClient.put(`/territories/${sid}`, data);
  },
  partialUpdate: ({ sid, ...data }) => {
    return axiosClient.patch(`/territories/${sid}`, data);
  },
};

export default teritoryApi;
