export const GET_LIST = 'features/project/GET_LIST';
export const GET_LIST_SUCCESS = 'features/project/GET_LIST_SUCCESS';
export const GET_LIST_ERROR = 'features/project/GET_LIST_ERROR';

export const CREATE = 'features/project/CREATE';
export const CREATE_SUCCESS = 'features/project/CREATE_SUCCESS';
export const CREATE_ERROR = 'features/project/CREATE_ERROR';

export const GET_DETAIL = 'features/project/GET_DETAIL';
export const GET_DETAIL_SUCCESS = 'features/project/GET_DETAIL_SUCCESS';
export const GET_DETAIL_ERROR = 'features/project/GET_DETAIL_ERROR';

export const UPDATE = 'features/project/UPDATE';
export const UPDATE_SUCCESS = 'features/project/UPDATE_SUCCESS';
export const UPDATE_ERROR = 'features/project/UPDATE_ERROR';

export const DELETE = 'features/project/DELETE';
export const DELETE_SUCCESS = 'features/project/DELETE_SUCCESS';
export const DELETE_ERROR = 'features/project/DELETE_ERROR';

export const SET_PARAMS = 'features/project/SET_PARAMS';
