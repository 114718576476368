import { takeEvery, call } from 'redux-saga/effects';
import * as actionTypes from './actionTypes';
import { planogramApi } from '../../../../api/projectApi';

function* handleGetPlanogramList({ payload }) {
  const { projectSid, params, callback } = payload;

  try {
    const result = yield call(planogramApi.getList, projectSid, params);

    callback({ error: false, data: result });
  } catch (error) {
    callback({ error: true, errors: error.errors });
  }
}

function* handleCreatePlanogram({ payload }) {
  const { data, callback } = payload;

  try {
    const result = yield call(planogramApi.create, data);

    callback({ error: false, data: result });
  } catch (error) {
    callback({ error: true, errors: error.errors });
  }
}

function* handleGetPlanogramDetail({ payload }) {
  const { data, callback } = payload;

  try {
    const result = yield call(planogramApi.getDetail, data);

    callback({ error: false, data: result });
  } catch (error) {
    callback({ error: true, errors: error.errors });
  }
}

function* handleUpdatePlanogram({ payload }) {
  const { data, callback } = payload;

  try {
    const result = yield call(planogramApi.update, data);

    callback({ error: false, data: result });
  } catch (error) {
    callback({ error: true, errors: error.errors });
  }
}

export default [
  takeEvery(actionTypes.GET_PLANOGRAM_LIST, handleGetPlanogramList),
  takeEvery(actionTypes.CREATE_PLANOGRAM, handleCreatePlanogram),
  takeEvery(actionTypes.GET_PLANOGRAM_DETAIL, handleGetPlanogramDetail),
  takeEvery(actionTypes.UPDATE_PLANOGRAM, handleUpdatePlanogram),
];
