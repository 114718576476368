export const COLOR_INFO = '#2f96b4';
export const COLOR_SUCCESS = '#51a351';
export const COLOR_WARNING = '#f89406';
export const COLOR_ERROR = '#bd362f';

export const COLOR_ACTIVE = '#87d068';
export const COLOR_INACTIVE = '#ff4d4f';

export const COLOR_PENDING_STATUS = '#FCB034';
export const COLOR_IN_PROCESS_STATUS = '#1890ff';
export const COLOR_SUCCESS_STATUS = '#87d068';
export const COLOR_FAILED_STATUS = '#ff4d4f';
export const COLOR_REJECT_STATUS = '#a566';
