export const GET_STORE_LIST = 'features/project/GET_STORE_LIST';
export const GET_STORE_LIST_SUCCESS = 'features/project/GET_STORE_LIST_SUCCESS';
export const GET_STORE_LIST_ERROR = 'features/project/GET_STORE_LIST_ERROR';

export const CREATE_STORE = 'features/project/CREATE_STORE';
export const CREATE_STORE_SUCCESS = 'features/project/CREATE_STORE_SUCCESS';
export const CREATE_STORE_ERROR = 'features/project/CREATE_STORE_ERROR';

export const GET_STORE_DETAIL = 'features/project/GET_STORE_DETAIL';
export const GET_STORE_DETAIL_SUCCESS =
  'features/project/GET_STORE_DETAIL_SUCCESS';
export const GET_STORE_DETAIL_ERROR = 'features/project/GET_STORE_DETAIL_ERROR';

export const UPDATE_STORE = 'features/project/UPDATE_STORE';
export const UPDATE_STORE_SUCCESS = 'features/project/UPDATE_STORE_SUCCESS';
export const UPDATE_STORE_ERROR = 'features/project/UPDATE_STORE_ERROR';
