import * as actionTypes from './actionTypes';

const initialState = {
  params: {
    page: 1,
    page_size: 10,
  },
};

const imageCategoryReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case actionTypes.SET_PARAMS: {
      return {
        ...state,
        params: {
          ...state.params,
          ...action.payload.params,
        },
      };
    }

    default:
      return state;
  }
};

export default imageCategoryReducer;
