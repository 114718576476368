export const ERROR_CODE = {
  NETWORK_ERROR: 'NETWORK_ERROR',
  UNDEFINED: 'UNDEFINED',
  ECONNABORTED: 'ECONNABORTED',
  NOT_FOUND: 'NOT_FOUND',
};

export const ERROR_MESSAGE = {
  [ERROR_CODE.NETWORK_ERROR]:
    'No Internet connection available. Please check again.',
  [ERROR_CODE.UNDEFINED]: 'Error data query.',
  [ERROR_CODE.ECONNABORTED]: 'Request expired. Please reload the page.',
  [ERROR_CODE.NOT_FOUND]: 'Not found. Please reload the page.',
};
