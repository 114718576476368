export const GET_LIST_TYPE = 'features/employee/GET_LIST_TYPE';

export const GET_LIST_JOB = 'features/employee/GET_LIST_JOB';

export const GET_LIST_STORE = 'features/employee/GET_LIST_STORE';

export const GET_LIST = 'features/employee/GET_LIST';
export const GET_LIST_SUCCESS = 'features/employee/GET_LIST_SUCCESS';
export const GET_LIST_ERROR = 'features/employee/GET_LIST_ERROR';

export const GET_LIST_CUSTOMER_COMPANY =
  'features/employee/GET_LIST_CUSTOMER_COMPANY';
export const GET_LIST_CUSTOMER_COMPANY_SUCCESS =
  'features/employee/GET_LIST_CUSTOMER_COMPANY_SUCCESS';
export const GET_LIST_CUSTOMER_COMPANY_ERROR =
  'features/employee/GET_LIST_CUSTOMER_COMPANY_ERROR';

export const GET_LIST_ACTIVITIES = 'features/employee/GET_LIST_ACTIVITIES';
export const GET_LIST_ACTIVITIES_SUCCESS =
  'features/employee/GET_LIST_ACTIVITIES_SUCCESS';
export const GET_LIST_ACTIVITIES_ERROR =
  'features/employee/GET_LIST_ACTIVITIES_ERROR';

export const GET_LIST_ACTIVITIES_FILTER =
  'features/employee/GET_LIST_ACTIVITIES_FILTER';
export const GET_LIST_ACTIVITIES_FILTER_SUCCESS =
  'features/employee/GET_LIST_ACTIVITIES_FILTER_SUCCESS';
export const GET_LIST_ACTIVITIES_FILTER_ERROR =
  'features/employee/GET_LIST_ACTIVITIES_FILTER_ERROR';

export const GET_LIST_COMPANY_TERRITORY_IN_EMPLOYEE =
  'features/employee/GET_LIST_COMPANY_TERRITORY_IN_EMPLOYEE';
export const GET_LIST_COMPANY_TERRITORY_IN_EMPLOYEE_SUCCESS =
  'features/employee/GET_LIST_COMPANY_TERRITORY_IN_EMPLOYEE_SUCCESS';
export const GET_LIST_COMPANY_TERRITORY_IN_EMPLOYEE_ERROR =
  'features/employee/GET_LIST_COMPANY_TERRITORY_IN_EMPLOYEE_ERROR';

export const CREATE = 'features/employee/CREATE';
export const CREATE_SUCCESS = 'features/employee/CREATE_SUCCESS';
export const CREATE_ERROR = 'features/employee/CREATE_ERROR';

export const CREATE_COMPANY_TERRITORY =
  'features/employee/CREATE_COMPANY_TERRITORY';
export const CREATE_COMPANY_TERRITORY_SUCCESS =
  'features/employee/CREATE_COMPANY_TERRITORY_SUCCESS';
export const CREATE_COMPANY_TERRITORY_ERROR =
  'features/employee/CREATE_COMPANY_TERRITORY_ERROR';

export const GET_DETAIL = 'features/employee/GET_DETAIL';
export const GET_DETAIL_SUCCESS = 'features/employee/GET_DETAIL_SUCCESS';
export const GET_DETAIL_ERROR = 'features/employee/GET_DETAIL_ERROR';

export const UPDATE = 'features/employee/UPDATE';
export const UPDATE_SUCCESS = 'features/employee/UPDATE_SUCCESS';
export const UPDATE_ERROR = 'features/employee/UPDATE_ERROR';

export const UPDATE_COMPANY_TERRITORY =
  'features/employee/UPDATEL_COMPANY_TERRITORY';
export const UPDATEL_COMPANY_TERRITORY_SUCCESS =
  'features/employee/UPDATE_COMPANY_TERRITORY_SUCCESS';
export const UPDATE_COMPANY_TERRITORY_ERROR =
  'features/employee/UPDATE_COMPANY_TERRITORY_ERROR';

export const DELETE_COMPANY_TERRITORY =
  'features/employee/DELETE_COMPANY_TERRITORY';
export const DELETE_COMPANY_TERRITORY_SUCCESS =
  'features/employee/DELETE_COMPANY_TERRITORY_SUCCESS';
export const DELETE_COMPANY_TERRITORY_ERROR =
  'features/employee/DELETE_COMPANY_TERRITORY_ERROR';

export const DELETE_EMPLOYEE_LOGOUT =
  'features/employee/DELETE_EMPLOYEE_LOGOUT';
export const DELETE_EMPLOYEE_LOGOUT_SUCCESS =
  'features/employee/DELETE_EMPLOYEE_LOGOUT_SUCCESS';
export const DELETE_EMPLOYEE_LOGOUT_ERROR =
  'features/employee/DELETE_EMPLOYEE_LOGOUT_ERROR';

export const DELETE_EMPLOYEE_REGISTER =
  'features/employee/DELETE_EMPLOYEE_REGISTER';
export const DELETE_EMPLOYEE_REGISTER_SUCCESS =
  'features/employee/DELETE_EMPLOYEE_REGISTER_SUCCESS';
export const DELETE_EMPLOYEE_REGISTER_ERROR =
  'features/employee/DELETE_EMPLOYEE_REGISTER_ERROR';

export const SET_PARAMS = 'features/employee/SET_PARAMS';
