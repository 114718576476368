import * as actionTypes from './actionTypes';

const initialState = {
  citiesState: [],
  states: [],
};

const publicReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case actionTypes.GET_LIST_CITY_STATE_SUCCESS: {
      // console.log(action.payload.data);
      const getStates = action.payload.data.reduce(
        (accumulator, currentValue) => {
          if (!accumulator[currentValue.state]) {
            accumulator[currentValue.state] = [];
          }
          accumulator[currentValue.state].push(currentValue);
          return accumulator;
        },
        {},
      );
      const states = Object.keys(getStates);
      // console.log('tieu bang : ', states);
      return {
        ...state,
        citiesState: getStates,
        states,
      };
    }
    default:
      return state;
  }
};

export default publicReducer;
