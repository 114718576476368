import axiosClient from './axiosClient';

const projectApi = {
  getList: (params) => {
    return axiosClient.get('/admin/projects/', { params });
  },
  create: (data) => {
    return axiosClient.post('/admin/projects/', data);
  },
  getDetail: (sid) => {
    return axiosClient.get(`/admin/projects/${sid}/`);
  },
  update: ({ sid, ...data }) => {
    return axiosClient.put(`/admin/projects/${sid}/`, data);
  },
  partialUpdate: ({ sid, ...data }) => {
    return axiosClient.patch(`/admin/projects/${sid}/`, data);
  },
};

const skuApi = {
  getList: (projectSid, params) => {
    return axiosClient.get(`/admin/projects/${projectSid}/skus/`, { params });
  },
  create: (data) => {
    return axiosClient.post(`/admin/projects/${data.project_sid}/skus/`, data);
  },
  getDetail: ({ projectSid, skuSid }) => {
    return axiosClient.get(`/admin/projects/${projectSid}/skus/${skuSid}/`);
  },
  update: ({ skuSid, ...data }) => {
    return axiosClient.put(
      `/admin/projects/${data.project_sid}/skus/${skuSid}/`,
      data,
    );
  },
};

const storeTypeApi = {
  getList: (projectSid, params) => {
    return axiosClient.get(`/admin/projects/${projectSid}/store-types/`, {
      params,
    });
  },
  create: (data) => {
    return axiosClient.post(
      `/admin/projects/${data.project_sid}/store-types/`,
      data,
    );
  },
  getDetail: ({ projectSid, storeTypeSid }) => {
    return axiosClient.get(
      `/admin/projects/${projectSid}/store-types/${storeTypeSid}/`,
    );
  },
  update: ({ storeTypeSid, ...data }) => {
    return axiosClient.put(
      `/admin/projects/${data.project_sid}/store-types/${storeTypeSid}/`,
      data,
    );
  },
};

const regionApi = {
  getList: (projectSid, params) => {
    return axiosClient.get(`/admin/projects/${projectSid}/regions/`, {
      params,
    });
  },
  create: (data) => {
    return axiosClient.post(
      `/admin/projects/${data.project_sid}/regions/`,
      data,
    );
  },
  getDetail: ({ projectSid, regionSid }) => {
    return axiosClient.get(
      `/admin/projects/${projectSid}/regions/${regionSid}/`,
    );
  },
  update: ({ regionSid, ...data }) => {
    return axiosClient.put(
      `/admin/projects/${data.project_sid}/regions/${regionSid}/`,
      data,
    );
  },
};

const storeApi = {
  getList: (projectSid, params) => {
    return axiosClient.get(`/admin/projects/${projectSid}/stores/`, {
      params,
    });
  },
  create: (data) => {
    return axiosClient.post(
      `/admin/projects/${data.project_sid}/stores/`,
      data,
    );
  },
  getDetail: ({ projectSid, storeSid }) => {
    return axiosClient.get(`/admin/projects/${projectSid}/stores/${storeSid}/`);
  },
  update: ({ storeSid, ...data }) => {
    return axiosClient.put(
      `/admin/projects/${data.project_sid}/stores/${storeSid}/`,
      data,
    );
  },
};

const planogramApi = {
  getList: (projectSid, params) => {
    return axiosClient.get(`/admin/projects/${projectSid}/planograms/`, {
      params,
    });
  },
  create: (data) => {
    return axiosClient.post(
      `/admin/projects/${data.project_sid}/planograms/`,
      data,
    );
  },
  getDetail: ({ projectSid, planogramSid }) => {
    return axiosClient.get(
      `/admin/projects/${projectSid}/planograms/${planogramSid}/`,
    );
  },
  update: ({ planogramSid, ...data }) => {
    return axiosClient.put(
      `/admin/projects/${data.project_sid}/planograms/${planogramSid}/`,
      data,
    );
  },
};

const storeTypePlanogramApi = {
  getList: (projectSid, params) => {
    return axiosClient.get(
      `/admin/projects/${projectSid}/store-type-planograms/`,
      {
        params,
      },
    );
  },
  create: (data) => {
    return axiosClient.post(
      `/admin/projects/${data.project_sid}/store-type-planograms/`,
      data,
    );
  },
  getDetail: ({ projectSid, storeTypePlanogramSid }) => {
    return axiosClient.get(
      `/admin/projects/${projectSid}/store-type-planograms/${storeTypePlanogramSid}/`,
    );
  },
  update: ({ storeTypePlanogramSid, ...data }) => {
    return axiosClient.put(
      `/admin/projects/${data.project_sid}/store-type-planograms/${storeTypePlanogramSid}/`,
      data,
    );
  },
};

const regionPlanogramApi = {
  getList: (projectSid, planogramSid, params) => {
    return axiosClient.get(
      `/admin/projects/${projectSid}/planograms/${planogramSid}/regions`,
      {
        params,
      },
    );
  },
  create: (data) => {
    return axiosClient.post(
      `/admin/projects/${data.project_sid}/planograms/${data.planogram_sid}/regions/`,
      data,
    );
  },
  getDetail: ({ projectSid, regionPlanogramSid }) => {
    return axiosClient.get(
      `/admin/projects/${projectSid}/region-planograms/${regionPlanogramSid}/`,
    );
  },
  update: (data) => {
    return axiosClient.put(
      `/admin/projects/${data.project_sid}/planograms/${data.planogram_sid}/regions/${data.sid}/`,
      data,
    );
  },
};

const skuPlanogramApi = {
  getList: (projectSid, planogramSid, params) => {
    return axiosClient.get(
      `/admin/projects/${projectSid}/planograms/${planogramSid}/skus/`,
      {
        params,
      },
    );
  },
  create: (data) => {
    return axiosClient.post(
      `/admin/projects/${data.project_sid}/planograms/${data.planogram_sid}/skus/`,
      data,
    );
  },
  getDetail: ({ projectSid, skuPlanogramSid }) => {
    return axiosClient.get(
      `/admin/projects/${projectSid}/planogram-skus/${skuPlanogramSid}/`,
    );
  },
  update: (data) => {
    return axiosClient.put(
      `/admin/projects/${data.project_sid}/planograms/${data.planogram_sid}/skus/${data.sid}/`,
      data,
    );
  },
};

const employeeApi = {
  getList: (projectSid, params) => {
    return axiosClient.get(`/admin/projects/${projectSid}/user-in-project/`, {
      params,
    });
  },
  create: (data) => {
    return axiosClient.post(
      `/admin/projects/${data.project_sid}/user-in-project/`,
      data,
    );
  },
  getDetail: ({ projectSid, employeeSid }) => {
    return axiosClient.get(
      `/admin/projects/${projectSid}/user-in-project/${employeeSid}/`,
    );
  },
  update: ({ employeeSid, ...data }) => {
    return axiosClient.put(
      `/admin/projects/${data.project_sid}/user-in-project/${employeeSid}/`,
      data,
    );
  },
};

const TeritoryApi = {
  getList: (projectSid, params) => {
    return axiosClient.get(`/admin/projects/${projectSid}/user-in-project/`, {
      params,
    });
  },
  create: (data) => {
    return axiosClient.post(
      `/admin/projects/${data.project_sid}/user-in-project/`,
      data,
    );
  },
  getDetail: ({ projectSid, employeeSid }) => {
    return axiosClient.get(
      `/admin/projects/${projectSid}/user-in-project/${employeeSid}/`,
    );
  },
  update: ({ employeeSid, ...data }) => {
    return axiosClient.put(
      `/admin/projects/${data.project_sid}/user-in-project/${employeeSid}/`,
      data,
    );
  },
};

const imageTypeApi = {
  getList: (projectSid, params) => {
    return axiosClient.get(`/admin/projects/${projectSid}/image-types/`, {
      params,
    });
  },
  create: (data) => {
    return axiosClient.post(
      `/admin/projects/${data.project_sid}/image-types/`,
      data,
    );
  },
  getDetail: ({ projectSid, imageTypeSid }) => {
    return axiosClient.get(
      `/admin/projects/${projectSid}/image-types/${imageTypeSid}/`,
    );
  },
  update: ({ imageTypeSid, ...data }) => {
    return axiosClient.put(
      `/admin/projects/${data.project_sid}/image-types/${imageTypeSid}/`,
      data,
    );
  },
};

export {
  projectApi,
  skuApi,
  storeTypeApi,
  regionApi,
  storeApi,
  planogramApi,
  storeTypePlanogramApi,
  regionPlanogramApi,
  skuPlanogramApi,
  employeeApi,
  imageTypeApi,
  TeritoryApi,
};
