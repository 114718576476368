export const GET_SKU_LIST = 'features/project/GET_SKU_LIST';
export const GET_SKU_LIST_SUCCESS = 'features/project/GET_SKU_LIST_SUCCESS';
export const GET_SKU_LIST_ERROR = 'features/project/GET_SKU_LIST_ERROR';

export const CREATE_SKU = 'features/project/CREATE_SKU';
export const CREATE_SKU_SUCCESS = 'features/project/CREATE_SKU_SUCCESS';
export const CREATE_SKU_ERROR = 'features/project/CREATE_SKU_ERROR';

export const GET_SKU_DETAIL = 'features/project/GET_SKU_DETAIL';
export const GET_SKU_DETAIL_SUCCESS = 'features/project/GET_SKU_DETAIL_SUCCESS';
export const GET_SKU_DETAIL_ERROR = 'features/project/GET_SKU_DETAIL_ERROR';

export const UPDATE_SKU = 'features/project/UPDATE_SKU';
export const UPDATE_SKU_SUCCESS = 'features/project/UPDATE_SKU_SUCCESS';
export const UPDATE_SKU_ERROR = 'features/project/UPDATE_SKU_ERROR';
