import * as actionType from './actionsTypes';

const initalState = {
  params: {
    page: 1,
    page_size: 10,
  },
};

const storeReducer = (state = initalState, action = {}) => {
  switch (action.type) {
    case actionType.SET_PARAMS:
      return {
        ...state,
        params: {
          ...state.params,
          ...action.payload.params,
        },
      };
    case actionType.UPLOAD_FILE_DATA:
      return {
        data_sheet: action.payload,
      };

    default:
      return state;
  }
};

export default storeReducer;
