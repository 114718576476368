import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { PersistGate } from 'redux-persist/integration/react';
import * as serviceWorker from './serviceWorker';

import App from './app/views/App';
import configureStore from './store';
import history from './utils/history';
import { handleCheckAuth } from './features/login/actions';
import { httpService } from './api/axiosClient';
import SimpleReactLightbox from 'simple-react-lightbox';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

// Create redux store with history
const getStoreFromStorage = localStorage.getItem('data_sheet')
  ? JSON.parse(localStorage.getItem('data_sheet'))
  : [];
const initialState = { store: { data_sheet: getStoreFromStorage } };
const { store, persistor } = configureStore(initialState, history);

httpService.setupInterceptors(store);
store.dispatch(handleCheckAuth());

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <ConnectedRouter history={history}>
        <GoogleReCaptchaProvider
          reCaptchaKey={process.env.REACT_APP_RECAPTCHA}
          scriptProps={{
            appendTo: 'head', // optional, default to "head", can be "head" or "body",
          }}
        >
          <SimpleReactLightbox>
            <App />
          </SimpleReactLightbox>
        </GoogleReCaptchaProvider>
      </ConnectedRouter>
    </PersistGate>
  </Provider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
