import { takeEvery, call } from 'redux-saga/effects';
import * as actionTypes from './actionsTypes';
import profileApi from '../../api/myProfileApi';
// import employeeApi from '../../api/employeeApi';

function* handleGetList({ payload }) {
  const { params, callback } = payload;

  try {
    const result = yield call(profileApi.getList, params);

    callback({ error: false, data: result });
  } catch (error) {
    callback({ error: true, errors: error.errors });
  }
}

function* handleCreate({ payload }) {
  const { data, callback } = payload;

  try {
    const result = yield call(profileApi.create, data);

    callback({ error: false, data: result });
  } catch (error) {
    callback({ error: true, errors: error.errors });
  }
}

function* handleCreateAvatar({ payload }) {
  const { data, callback } = payload;

  try {
    const result = yield call(profileApi.createAvatar, data);

    callback({ error: false, data: result });
  } catch (error) {
    callback({ error: true, errors: error.errors });
  }
}

function* handleGetDetail({ payload }) {
  const { callback } = payload;

  try {
    const result = yield call(profileApi.getDetail);

    callback({ error: false, data: result });
  } catch (error) {
    callback({ error: true, errors: error.errors });
  }
}

function* handleUpdate({ payload }) {
  const { data, callback } = payload;
  // console.log('handleUpdate', data);
  try {
    const { new_password, avatar, sid, ...restData } = data;
    if (avatar) {
      const formData = new FormData();
      formData.append('file_avatar', avatar);
      const changeAvatar = yield call(profileApi.createAvatar, formData);
    }
    if (new_password) {
      const changePassword = yield call(profileApi.changePassword, { 'new_password': new_password });
    }
    const result = yield call(profileApi.update, { sid, ...restData });
    callback({ error: false });
  } catch (error) {
    callback({ error: true, errors: error.errors });
  }
}

function* handleChangePassword({ payload }) {
  const { data, callback } = payload;

  try {
    const result = yield call(profileApi.changePassword, data);

    callback({ error: false, data: result });
  } catch (error) {
    callback({ error: true, errors: error.errors });
  }
}

export default [
  takeEvery(actionTypes.GET_LIST, handleGetList),
  takeEvery(actionTypes.CREATE, handleCreate),
  takeEvery(actionTypes.CREATE_AVATAR, handleCreateAvatar),
  takeEvery(actionTypes.GET_DETAIL, handleGetDetail),
  takeEvery(actionTypes.UPDATE, handleUpdate),
  takeEvery(actionTypes.CHANGEPASSWORD, handleChangePassword),
];
