import * as actionTypes from './actionTypes';

const doGetListCityState = () => ({
  type: actionTypes.GET_LIST_CITY_STATE,
});

const doGetListCityStateSuccess = (data) => ({
  type: actionTypes.GET_LIST_CITY_STATE_SUCCESS,
  payload: { data },
});

export { doGetListCityState, doGetListCityStateSuccess };
