import { takeEvery, call, put } from 'redux-saga/effects';
import * as actionTypes from './actionTypes';
import cityStateApi from '../api/cityStateApi';
import * as actions from './actions';

function* handleGetListCityState() {
  try {
    const result = yield call(cityStateApi.getList);
    yield put(actions.doGetListCityStateSuccess(result.data));
    // console.log(result);
  } catch (error) {}
}

export default [
  takeEvery(actionTypes.GET_LIST_CITY_STATE, handleGetListCityState),
];
