import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ConfigProvider } from 'antd';
import { ThemeProvider } from 'styled-components';
import { IntlProvider } from 'react-intl';
import AppLocale from '../../assets/translations';
import themes from '../../assets/themes';

import GlobalStyles from '../../assets/styles/globalStyle';
import Routes from '../../router';

import { doGetListCityState } from '../actions';

function App() {
  const dispatch = useDispatch();
  const [currentLocale] = useState('en');
  // console.log('AppLocale: ', AppLocale, themes);
  const dataCity = useSelector((state) => state.public.citiesState);
  useEffect(() => {
    if (dataCity && dataCity.length === 0) {
      dispatch(doGetListCityState());
    }
  }, []);

  return (
    <ConfigProvider locale={AppLocale[currentLocale].antd}>
      <IntlProvider
        locale={AppLocale[currentLocale].locale}
        messages={AppLocale[currentLocale].message}
      >
        <ThemeProvider theme={themes}>
          <GlobalStyles />
          <Routes />
        </ThemeProvider>
      </IntlProvider>
    </ConfigProvider>
  );
}

export default App;
