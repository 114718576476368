import axiosClient from './axiosClient';

const storeApi = {
  getList: (params) => {
    return axiosClient.get('/stores', { params });
  },
  getFilterListPhotoStore: (params) => {
    return axiosClient.get(`/store/photos`, { params });
  },
  create: (data) => {
    return axiosClient.post('/stores', data);
  },
  postPhoto: (sid, data) => {
    return axiosClient.post(`/stores/${sid}/photo`, data);
  },
  postFile: (sid, data) => {
    return axiosClient.post(`/stores/${sid}/file`, data);
  },
  getDetail: (sid) => {
    return axiosClient.get(`/stores/${sid}`);
  },
  update: ({ sid, ...data }) => {
    return axiosClient.put(`/stores/${sid}`, data);
  },
  getStoreType: (params) => {
    return axiosClient.get(`/store_type`, { params });
  },
  getClimateRegion: (params) => {
    return axiosClient.get(`/climate_region`, { params });
  },
  getDataJobStore: ({ sid }) => {
    return axiosClient.get(`/jobs/store/${sid}`);
  }
};
export default storeApi;
