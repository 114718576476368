export const GET_EMPLOYEE_LIST = 'features/project/GET_EMPLOYEE_LIST';
export const GET_EMPLOYEE_LIST_SUCCESS =
  'features/project/GET_EMPLOYEE_LIST_SUCCESS';
export const GET_EMPLOYEE_LIST_ERROR =
  'features/project/GET_EMPLOYEE_LIST_ERROR';

export const CREATE_EMPLOYEE = 'features/project/CREATE_EMPLOYEE';
export const CREATE_EMPLOYEE_SUCCESS =
  'features/project/CREATE_EMPLOYEE_SUCCESS';
export const CREATE_EMPLOYEE_ERROR = 'features/project/CREATE_EMPLOYEE_ERROR';

export const GET_EMPLOYEE_DETAIL = 'features/project/GET_EMPLOYEE_DETAIL';
export const GET_EMPLOYEE_DETAIL_SUCCESS =
  'features/project/GET_EMPLOYEE_DETAIL_SUCCESS';
export const GET_EMPLOYEE_DETAIL_ERROR =
  'features/project/GET_EMPLOYEE_DETAIL_ERROR';

export const UPDATE_EMPLOYEE = 'features/project/UPDATE_EMPLOYEE';
export const UPDATE_EMPLOYEE_SUCCESS =
  'features/project/UPDATE_EMPLOYEE_SUCCESS';
export const UPDATE_EMPLOYEE_ERROR = 'features/project/UPDATE_EMPLOYEE_ERROR';
