import React, { useState, useEffect } from 'react';
import { PauseCircleOutlined, PlayCircleOutlined } from '@ant-design/icons';
import { Button } from 'antd';

const useAudio = (url) => {
  const [audio] = useState(new Audio(url));
  const [playing, setPlaying] = useState(false);

  const toggle = () => setPlaying(!playing);

  useEffect(() => {
    playing ? audio.play() : audio.pause();
  }, [playing]);

  useEffect(() => {
    audio.addEventListener('ended', () => setPlaying(false));
    return () => {
      audio.removeEventListener('ended', () => setPlaying(false));
    };
  }, []);

  return [playing, toggle];
};

const AudioComponent = ({ url }) => {
  const [playing, toggle] = useAudio(url);

  return (
    <Button
      danger
      size="large"
      type="primary"
      icon={playing ? <PauseCircleOutlined /> : <PlayCircleOutlined />}
      onClick={toggle}
    />
  );
};

export default React.memo(AudioComponent);
