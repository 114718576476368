import axiosClient from './axiosClient';

const importApi = {
  downloadTemplate: (params) => {
    return axiosClient.get('/admin/template-files/', { params });
  },
  getList: (params) => {
    return axiosClient.get('/admin/import-files/', { params });
  },
  create: (data) => {
    return axiosClient.post('/admin/import-files/', data);
  },
};

const exportApi = {};

export { importApi, exportApi };
