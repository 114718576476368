import axiosClient from './axiosClient';

const authApi = {
  login: (data) => {
    return axiosClient.post('/login', data);
  },
  resetPassword: (data) => {
    return axiosClient.post('/resetPassword', data);
  },
  restorePassword: (data) => {
    return axiosClient.post('/restorePassword', data);
  },
  checkLoginToken: (data) => {
    return axiosClient.post('/checklogintoken', data);
  },
  checkTokenResetPassword: (data) => {
    return axiosClient.post('/check/token', data);
  }
};

export default authApi;
