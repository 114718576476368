const theme = {};

theme.palette = {
  primary: ['#727cf5'],
  color: ['#fff', '#000'],
  text: [
    '#000', // main text
    '#788195', // feature title
  ],
  background: [
    'linear-gradient(135deg,#8f75da 0,#727cf5 60%)', // 0
    '#ffffff',
    '#4482ff', // title box
  ],
  border: ['#e9e9e9'],
  error: [
    '#ff4d4f', // 0: login hint
  ],
};

theme.fonts = {
  primary: `'Roboto', sans-serif`,
};

export default theme;
