import options from '../containers/Sidebar/options';
import { isServer } from './isServer';

export function getDefaultPath() {
  const getParent = lastRoute => {
    const parent = [];
    if (!lastRoute) return parent;

    const getFirst = lastRoute.split('/')[0];
    parent.push(getFirst);

    options.forEach(option => {
      if (option.children) {
        option.children.forEach(child => {
          if (child.key === getFirst) {
            parent.push(option.key);
          }
        });
      }
    });

    return parent;
  };
  if (!isServer && window.location.pathname) {
    if (window.location.pathname.length > 0) {
      const lastRoute = window.location.pathname.substr(1);

      return getParent(lastRoute);
    }
  }
  return [];
}