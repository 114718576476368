export const LOGIN = 'features/login/LOGIN';
export const LOGIN_SUCCESS = 'features/login/LOGIN_SUCCESS';
export const LOGIN_ERROR = 'features/login/LOGIN_ERROR';

export const RESET_PASSWORD = 'features/login/RESET_PASSWORD';
export const RESET_PASSWORD_SUCCESS = 'features/login/RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_ERROR = 'features/login/RESET_PASSWORD_ERROR';

export const LOGOUT = 'features/login/LOGOUT';

export const CHECK_AUTH = 'features/login/CHECK_AUTH';
