import { takeEvery, call } from 'redux-saga/effects';
import * as actionTypes from './actionTypes';
import { regionPlanogramApi } from '../../../../api/projectApi';

function* handleGetRegionPlanogramList({ payload }) {
  const { projectSid, planogramSid, params, callback } = payload;

  try {
    const result = yield call(
      regionPlanogramApi.getList,
      projectSid,
      planogramSid,
      params,
    );

    callback({ error: false, data: result });
  } catch (error) {
    callback({ error: true, errors: error.errors });
  }
}

function* handleCreateRegionPlanogram({ payload }) {
  const { data, callback } = payload;

  try {
    const result = yield call(regionPlanogramApi.create, data);

    callback({ error: false, data: result });
  } catch (error) {
    callback({ error: true, errors: error.errors });
  }
}

function* handleGetRegionPlanogramDetail({ payload }) {
  const { data, callback } = payload;

  try {
    const result = yield call(regionPlanogramApi.getDetail, data);

    callback({ error: false, data: result });
  } catch (error) {
    callback({ error: true, errors: error.errors });
  }
}

function* handleUpdateRegionPlanogram({ payload }) {
  const { data, callback } = payload;

  try {
    const result = yield call(regionPlanogramApi.update, data);

    callback({ error: false, data: result });
  } catch (error) {
    callback({ error: true, errors: error.errors });
  }
}

export default [
  takeEvery(
    actionTypes.GET_REGION_PLANOGRAM_LIST,
    handleGetRegionPlanogramList,
  ),
  takeEvery(actionTypes.CREATE_REGION_PLANOGRAM, handleCreateRegionPlanogram),
  takeEvery(
    actionTypes.GET_REGION_PLANOGRAM_DETAIL,
    handleGetRegionPlanogramDetail,
  ),
  takeEvery(actionTypes.UPDATE_REGION_PLANOGRAM, handleUpdateRegionPlanogram),
];
