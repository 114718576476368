export const GET_LIST = 'features/companies/GET_LIST';
export const GET_LIST_SUCCESS = 'features/companies/GET_LIST_SUCCESS';
export const GET_LIST_ERROR = 'features/companies/GET_LIST_ERROR';

export const GET_LIST_FULL = 'features/companies/GET_LIST_FULL';
export const GET_LIST_FULL_SUCCESS = 'features/companies/GET_LIST_FULL_SUCCESS';
export const GET_LIST_FULL_ERROR = 'features/companies/GET_LIST_FULL_ERROR';

export const GET_LIST_COMPANY_TERRITORY =
  'features/companies/GET_LIST_COMPANY_TERRITORY';
export const GET_LIST_COMPANY_TERRITORY_SUCCESS =
  'features/companies/GET_LIST_COMPANY_TERRITORY_SUCCESS';
export const GET_LIST_COMPANY_TERRITORY_ERROR =
  'features/companies/GET_LIST_COMPANY_TERRITORY_ERROR';

export const CREATE = 'features/companies/CREATE';
export const CREATE_SUCCESS = 'features/companies/CREATE_SUCCESS';
export const CREATE_ERROR = 'features/companies/CREATE_ERROR';

export const GET_DETAIL = 'features/companies/GET_DETAIL';
export const GET_DETAIL_SUCCESS = 'features/companies/GET_DETAIL_SUCCESS';
export const GET_DETAIL_ERROR = 'features/companies/GET_DETAIL_ERROR';

export const UPDATE = 'features/companies/UPDATE';
export const UPDATE_SUCCESS = 'features/companies/UPDATE_SUCCESS';
export const UPDATE_ERROR = 'features/companies/UPDATE_ERROR';

export const SET_PARAMS = 'features/companies/SET_PARAMS';
