import { takeEvery, call } from 'redux-saga/effects';
import * as actionTypes from './actionTypes';
import { storeTypePlanogramApi } from '../../../../api/projectApi';

function* handleGetStoreTypePlanogramList({ payload }) {
  const { projectSid, params, callback } = payload;

  try {
    const result = yield call(
      storeTypePlanogramApi.getList,
      projectSid,
      params,
    );

    callback({ error: false, data: result });
  } catch (error) {
    callback({ error: true, errors: error.errors });
  }
}

function* handleCreateStoreTypePlanogram({ payload }) {
  const { data, callback } = payload;

  try {
    const result = yield call(storeTypePlanogramApi.create, data);

    callback({ error: false, data: result });
  } catch (error) {
    callback({ error: true, errors: error.errors });
  }
}

function* handleGetStoreTypePlanogramDetail({ payload }) {
  const { data, callback } = payload;

  try {
    const result = yield call(storeTypePlanogramApi.getDetail, data);

    callback({ error: false, data: result });
  } catch (error) {
    callback({ error: true, errors: error.errors });
  }
}

function* handleUpdateStoreTypePlanogram({ payload }) {
  const { data, callback } = payload;

  try {
    const result = yield call(storeTypePlanogramApi.update, data);

    callback({ error: false, data: result });
  } catch (error) {
    callback({ error: true, errors: error.errors });
  }
}

export default [
  takeEvery(
    actionTypes.GET_STORE_TYPE_PLANOGRAM_LIST,
    handleGetStoreTypePlanogramList,
  ),
  takeEvery(
    actionTypes.CREATE_STORE_TYPE_PLANOGRAM,
    handleCreateStoreTypePlanogram,
  ),
  takeEvery(
    actionTypes.GET_STORE_TYPE_PLANOGRAM_DETAIL,
    handleGetStoreTypePlanogramDetail,
  ),
  takeEvery(
    actionTypes.UPDATE_STORE_TYPE_PLANOGRAM,
    handleUpdateStoreTypePlanogram,
  ),
];
