import { takeEvery, call } from 'redux-saga/effects';
import * as actionTypes from './actionTypes';
import { employeeApi } from '../../../../api/projectApi';

function* handleGetEmployeeList({ payload }) {
  const { projectSid, params, callback } = payload;

  try {
    const result = yield call(employeeApi.getList, projectSid, params);

    callback({ error: false, data: result });
  } catch (error) {
    callback({ error: true, errors: error.errors });
  }
}

function* handleCreateEmployee({ payload }) {
  const { data, callback } = payload;

  try {
    const result = yield call(employeeApi.create, data);

    callback({ error: false, data: result });
  } catch (error) {
    callback({ error: true, errors: error.errors });
  }
}

function* handleGetEmployeeDetail({ payload }) {
  const { data, callback } = payload;

  try {
    const result = yield call(employeeApi.getDetail, data);

    callback({ error: false, data: result });
  } catch (error) {
    callback({ error: true, errors: error.errors });
  }
}

function* handleUpdateEmployee({ payload }) {
  const { data, callback } = payload;

  try {
    const result = yield call(employeeApi.update, data);

    callback({ error: false, data: result });
  } catch (error) {
    callback({ error: true, errors: error.errors });
  }
}

export default [
  takeEvery(actionTypes.GET_EMPLOYEE_LIST, handleGetEmployeeList),
  takeEvery(actionTypes.CREATE_EMPLOYEE, handleCreateEmployee),
  takeEvery(actionTypes.GET_EMPLOYEE_DETAIL, handleGetEmployeeDetail),
  takeEvery(actionTypes.UPDATE_EMPLOYEE, handleUpdateEmployee),
];
