import { takeEvery, call } from 'redux-saga/effects';
import * as actionTypes from './actionTypes';
import employeeApi from '../../api/employeeApi';

function* handleGetListType({ payload }) {
  const { params, callback } = payload;

  try {
    const result = yield call(employeeApi.getListType, params);

    callback({ error: false, data: result });
  } catch (error) {
    callback({ error: true, errors: error.errors });
  }
}

function* handleGetListJob({ payload }) {
  const { params, callback } = payload;

  try {
    const result = yield call(employeeApi.getListJob, params);

    callback({ error: false, data: result });
  } catch (error) {
    callback({ error: true, errors: error.errors });
  }
}

function* handleGetListStore({ payload }) {
  const { params, callback } = payload;

  try {
    const result = yield call(employeeApi.getListStore, params);

    callback({ error: false, data: result });
  } catch (error) {
    callback({ error: true, errors: error.errors });
  }
}

function* handleGetList({ payload }) {
  const { params, callback } = payload;

  try {
    const result = yield call(employeeApi.getList, params);

    callback({ error: false, data: result });
  } catch (error) {
    callback({ error: true, errors: error.errors });
  }
}

function* handleGetListCustomerCompany({ payload }) {
  const { params, callback } = payload;

  try {
    const result = yield call(employeeApi.getListCustomerToCompany, params);

    callback({ error: false, data: result });
  } catch (error) {
    callback({ error: true, errors: error.errors });
  }
}

function* handleGetListActivities({ payload }) {
  const { params, callback } = payload;

  try {
    const result = yield call(employeeApi.getListActivities, params);

    callback({ error: false, data: result });
  } catch (error) {
    callback({ error: true, errors: error.errors });
  }
}

function* handleGetListActivitiesFilter({ payload }) {
  const { sid, callback } = payload;

  try {
    const result = yield call(employeeApi.getListActivitiesFilter, sid);

    callback({ error: false, data: result });
  } catch (error) {
    callback({ error: true, errors: error.errors });
  }
}

function* handleGetListCompanyTerritoryInEmployee({ payload }) {
  const { sid, params, callback } = payload;

  try {
    const result = yield call(
      employeeApi.getListCompanyInEmployee,
      sid,
      params,
    );

    callback({ error: false, data: result });
  } catch (error) {
    callback({ error: true, errors: error.errors });
  }
}

function* handleCreate({ payload }) {
  const { data, callback } = payload;

  try {
    const result = yield call(employeeApi.create, data);

    callback({ error: false, data: result });
  } catch (error) {
    callback({ error: true, errors: error.errors });
  }
}

function* handleCreateCompanyTerritory({ payload }) {
  const { sid, data, callback } = payload;

  try {
    const result = yield call(employeeApi.createCompanyTerritory, sid, data);

    callback({ error: false, data: result });
  } catch (error) {
    callback({ error: true, errors: error.errors });
  }
}

function* handleGetDetail({ payload }) {
  const { sid, callback } = payload;

  try {
    const result = yield call(employeeApi.getDetail, sid);

    callback({ error: false, data: result });
  } catch (error) {
    callback({ error: true, errors: error.errors });
  }
}

function* handleUpdate({ payload }) {
  const { data, callback } = payload;

  try {
    const { avatar, sid, ...restData } = data;
    if (avatar) {
      const formData = new FormData();
      formData.append('avatar', avatar);
      const changeAvatar = yield call(employeeApi.updateAvatar, {
        sid,
        data: formData,
      });
    }
    const result = yield call(employeeApi.update, { sid, ...restData });
    callback({ error: false });
  } catch (error) {
    callback({ error: true, errors: error.errors });
  }
}

function* handleUpdateCompanyTerritory({ payload }) {
  const { data, callback } = payload;

  try {
    const result = yield call(employeeApi.updateCompanyTerritory, data);

    callback({ error: false, data: result });
  } catch (error) {
    callback({ error: true, errors: error.errors });
  }
}

function* handleDeleteCompanyTerritory({ payload }) {
  const { id, callback } = payload;

  try {
    const result = yield call(employeeApi.deleteCompanyTerritory, id);

    callback({ error: false, data: result });
  } catch (error) {
    callback({ error: true, errors: error.errors });
  }
}

function* handleDeleteEmployeeLogout({ payload }) {
  const { sid, callback } = payload;

  try {
    const result = yield call(employeeApi.deleteEmployeeLogout, sid);

    callback({ error: false, data: result });
  } catch (error) {
    callback({ error: true, errors: error.errors });
  }
}

function* handleDeleteEmployeeRegister({ payload }) {
  const { sid, callback } = payload;

  try {
    const result = yield call(employeeApi.deleteEmployeeRegister, sid);

    callback({ error: false, data: result });
  } catch (error) {
    callback({ error: true, errors: error.errors });
  }
}

export default [
  takeEvery(actionTypes.GET_LIST_TYPE, handleGetListType),
  takeEvery(actionTypes.GET_LIST_JOB, handleGetListJob),
  takeEvery(actionTypes.GET_LIST_STORE, handleGetListStore),
  takeEvery(actionTypes.GET_LIST, handleGetList),
  takeEvery(
    actionTypes.GET_LIST_CUSTOMER_COMPANY,
    handleGetListCustomerCompany,
  ),
  takeEvery(actionTypes.GET_LIST_ACTIVITIES, handleGetListActivities),
  takeEvery(
    actionTypes.GET_LIST_ACTIVITIES_FILTER,
    handleGetListActivitiesFilter,
  ),
  takeEvery(
    actionTypes.GET_LIST_COMPANY_TERRITORY_IN_EMPLOYEE,
    handleGetListCompanyTerritoryInEmployee,
  ),
  takeEvery(actionTypes.CREATE, handleCreate),
  takeEvery(actionTypes.CREATE_COMPANY_TERRITORY, handleCreateCompanyTerritory),
  takeEvery(actionTypes.GET_DETAIL, handleGetDetail),
  takeEvery(actionTypes.UPDATE, handleUpdate),
  takeEvery(actionTypes.UPDATE_COMPANY_TERRITORY, handleUpdateCompanyTerritory),
  takeEvery(actionTypes.DELETE_COMPANY_TERRITORY, handleDeleteCompanyTerritory),
  takeEvery(actionTypes.DELETE_EMPLOYEE_LOGOUT, handleDeleteEmployeeLogout),
  takeEvery(actionTypes.DELETE_EMPLOYEE_REGISTER, handleDeleteEmployeeRegister),
];
