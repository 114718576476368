import axiosClient from './axiosClient';

const profileApi = {
  getList: (params) => {
    return axiosClient.get('/profile', { params });
  },
  create: (data) => {
    return axiosClient.post('/profiles', data);
  },
  getDetail: (sid) => {
    return axiosClient.get(`/profile`);
  },
  update: ({ sid, ...data }) => {
    return axiosClient.put(`profile/updateInfo`, data);
  },
  partialUpdate: ({ sid, ...data }) => {
    return axiosClient.patch(`/profiles/${sid}`, data);
  },
  createAvatar: (data) => {
    return axiosClient.post('/profile/upload-avatar', data);
  },
  changePassword: (data) => {
    return axiosClient.post(`/auth/change-password-new`, data);
  }
};

export default profileApi;
