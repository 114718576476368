import { ERROR_CODE, ERROR_MESSAGE } from '../constants/errors';

function convertDataError(error) {
  // eslint-disable-next-line prefer-const
  const {
    code = ERROR_CODE.NOT_FOUND,
    message = ERROR_MESSAGE[ERROR_CODE.NOT_FOUND],
    status,
    errors = {},
  } = error;

  if (message && message.search('Cannot read property') >= 0) {
    return {
      code: ERROR_CODE.UNDEFINED,
      message: ERROR_MESSAGE[ERROR_CODE.UNDEFINED],
      status,
      errors,
    };
  }

  if (message && message.search('Network Error') >= 0) {
    return {
      code: ERROR_CODE.NETWORK_ERROR,
      message: ERROR_MESSAGE[ERROR_CODE.NETWORK_ERROR],
      status,
      errors,
    };
  }

  if (code === ERROR_CODE.ECONNABORTED) {
    return {
      code: ERROR_CODE.ECONNABORTED,
      message: ERROR_MESSAGE[ERROR_CODE.ECONNABORTED],
      status: 408,
      errors,
    };
  }

  return {
    code,
    message,
    status,
    errors,
  };
}

export function handleErrorMessage(err) {
  const { response } = err;

  if (response) {
    return convertDataError({
      ...response.data,
      // eslint-disable-next-line camelcase
      code: response?.data?.message_code,
      status: response.status,
    });
  }

  return convertDataError(err);
}
