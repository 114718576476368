import { get, setWith, clone, cloneDeep, set } from 'lodash';
import * as actionTypes from './actionsTypes';
import { stringToSlug } from './utils';

const initialState = {
  items: [],
};

const STRUCTURE = {
  component_type: null,
  keyboard_type: 'default',
  component_code: null,
  show: true,
  label: '',
  validate: {
    required: false,
  },
  default_value: null,
  value: null,
};

const OPTION_STRUCTURE = {
  value: '',
  label: '',
};

const demoReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case actionTypes.ADD_COMPONENT: {
      const { type } = action.payload;

      const newComponent = {
        ...STRUCTURE,
        component_type: type,
        component_code: `EX${Math.random() * 1000000}`,
      };

      if (type === 'SELECT') {
        newComponent.options = [
          { ...OPTION_STRUCTURE, id: `EX${Math.random() * 1000000}` },
        ];
        newComponent.actions = {};
      }

      return {
        ...state,
        items: [...state.items, newComponent],
      };
    }

    case actionTypes.DELETE: {
      const { path, index } = action.payload;
      const newItems = get(state, path).filter(
        (_, itemIndex) => itemIndex !== index,
      );
      const newState = setWith(clone(state), `${path}`, newItems, clone);

      return newState;
    }

    case actionTypes.CHANGE_VALUE: {
      const { path, value, index, key } = action.payload;
      const newState = cloneDeep(state);
      const checkOptionPath = path.split('.');

      set(newState, `${path}.${index}.${key}`, value);
      if (checkOptionPath[checkOptionPath.length - 1] === 'options') {
        set(newState, `${path}.${index}.value`, `key-${stringToSlug(value)}`);
      }

      return newState;
    }

    case actionTypes.ADD_OPTION: {
      const { path, isOtherKey } = action.payload;
      const newState = cloneDeep(state);
      const findOtherKeyIndex = get(newState, path).findIndex(
        (item) => item.value === 'key-khac',
      );
      let newArr = [];

      const newData = isOtherKey
        ? {
            value: 'key-khac',
            label: 'Khác',
            id: `EX${Math.random() * 1000000}`,
          }
        : {
            ...OPTION_STRUCTURE,
            id: `EX${Math.random() * 1000000}`,
          };

      if (findOtherKeyIndex === -1) {
        newArr = [...get(newState, path), newData];
      } else {
        newArr = [
          ...get(newState, path).slice(0, findOtherKeyIndex),
          newData,
          ...get(newState, path).slice(findOtherKeyIndex),
        ];
      }
      set(newState, path, newArr);

      return newState;
    }

    default:
      return state;
  }
};

export default demoReducer;
