import { takeEvery, call, all } from 'redux-saga/effects';
import * as actionTypes from './actionTypes';
import photoApi from '../../api/photoApi';

function* handleGetList({ payload }) {
  const { params, callback } = payload;
  // console.log('params : ', params);
  try {
    const result = yield call(photoApi.getList, params);

    callback({ error: false, data: result });
  } catch (error) {
    callback({ error: true, errors: error.errors });
  }
}
function* handleGetListJobsCompany({ payload }) {
  const { territory_sid, company_sid, params, callback } = payload;

  try {
    const result = yield call(
      photoApi.getListJobOfCompany,
      territory_sid,
      company_sid,
      params,
    );

    callback({ error: false, data: result });
  } catch (error) {
    callback({ error: true, errors: error.errors });
  }
}
function* handleGetListStoresCompany({ payload }) {
  const { territory_sid, company_sid, params, callback } = payload;

  try {
    const result = yield call(
      photoApi.getListStoreOfCompany,
      territory_sid,
      company_sid,
      params,
    );

    callback({ error: false, data: result });
  } catch (error) {
    callback({ error: true, errors: error.errors });
  }
}
function* handleGetListPhotoElevation({ payload }) {
  const { params, callback } = payload;

  try {
    const result = yield call(photoApi.getListPhotoElevation, params);

    callback({ error: false, data: result });
  } catch (error) {
    callback({ error: true, errors: error.errors });
  }
}

function* handleGetListPhotoTag({ payload }) {
  const { params, callback } = payload;

  try {
    const result = yield call(photoApi.getListPhotoTag, params);

    callback({ error: false, data: result });
  } catch (error) {
    callback({ error: true, errors: error.errors });
  }
}
function* handleCreatePhoto({ payload }) {
  const { data, callback } = payload;

  try {
    const result = yield call(photoApi.createPhoto, data);

    callback({ error: false, data: result });
  } catch (error) {
    callback({ error: true, errors: error.errors });
  }
}

function* handleUpdate({ payload }) {
  const { data, callback } = payload;

  try {
    const { img_photo, sid, ...restData } = data;
    if (img_photo) {
      const formData = new FormData();
      formData.append('img_photo', img_photo);
      const changeImg = yield call(photoApi.postPhoto, {
        sid,
        data: formData,
      });
    }
    const result = yield call(photoApi.update, { sid, ...restData });
    callback({ error: false });
  } catch (error) {
    callback({ error: true, errors: error.errors });
  }
}

function* handleDeletePhoto({ payload }) {
  const { data, callback } = payload;

  try {
    // console.log('data delete :', data);
    const result = yield call(photoApi.deletePhoto, data);

    callback({ error: false, data: result });
  } catch (error) {
    callback({ error: true, errors: error.errors });
  }
}

function* handleDownloadPhoto({ payload }) {
  const { data, callback } = payload;

  try {
    // console.log('data download :', data);
    const result = yield call(photoApi.downloadPhoto, data);

    callback({ error: false, data: result });
  } catch (error) {
    callback({ error: true, errors: error.errors });
  }
}

function* handleGetPhotoInterior({ payload }) {
  const { params, callback } = payload;
  // console.log('params : ', params);
  try {
    const result = yield call(photoApi.getListPhotoInteriorExterior, params);

    callback({ error: false, data: result });
  } catch (error) {
    callback({ error: true, errors: error.errors });
  }
}

export default [
  takeEvery(actionTypes.GET_LIST, handleGetList),
  takeEvery(actionTypes.GET_LIST_JOBS_OF_COMPANY, handleGetListJobsCompany),
  takeEvery(actionTypes.GET_LIST_STORES_OF_COMPANY, handleGetListStoresCompany),
  takeEvery(actionTypes.GET_LIST_PHOTO_ELEVATIONS, handleGetListPhotoElevation),
  takeEvery(actionTypes.GET_LIST_PHOTO_TAG, handleGetListPhotoTag),
  takeEvery(actionTypes.CREATE, handleCreatePhoto),
  takeEvery(actionTypes.DELETE, handleDeletePhoto),
  takeEvery(actionTypes.DOWNLOAD, handleDownloadPhoto),
  takeEvery(actionTypes.UPDATE, handleUpdate),
  takeEvery(actionTypes.GET_PHOTO_INTERIOR, handleGetPhotoInterior),
];
