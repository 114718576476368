export const GET_LIST = 'features/teritory/GET_LIST';
export const GET_LIST_SUCCESS = 'features/teritory/GET_LIST_SUCCESS';
export const GET_LIST_ERROR = 'features/teritory/GET_LIST_ERROR';

export const CREATE = 'features/teritory/CREATE';
export const CREATE_SUCCESS = 'features/teritory/CREATE_SUCCESS';
export const CREATE_ERROR = 'features/teritory/CREATE_ERROR';

export const GET_DETAIL = 'features/teritory/GET_DETAIL';
export const GET_DETAIL_SUCCESS = 'features/teritory/GET_DETAIL_SUCCESS';
export const GET_DETAIL_ERROR = 'features/teritory/GET_DETAIL_ERROR';

export const UPDATE = 'features/teritory/UPDATE';
export const UPDATE_SUCCESS = 'features/teritory/UPDATE_SUCCESS';
export const UPDATE_ERROR = 'features/teritory/UPDATE_ERROR';

export const SET_PARAMS = 'features/teritory/SET_PARAMS';
